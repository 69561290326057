<template>
  <div class="frame-page">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      v-model:currentPage="currentPage" :page-sizes="conf.pageSizeOption" :page-size="conf.pageSize" :total="total"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "VuePagination",
  props: {
    conf: {
      type: Object,
      default: function () {
        return { pageSize: 10, pageSizeOption: [10, 20, 50, 100] }
      }
    },
    total: {
      type: Number,
      default: 4000
    },
    onChange: {
      type: Function
    }
  },
  methods: {
    handleSizeChange (val) {
      //防止请求两次
      if (this.currentPage * val > this.total) this.turnFirstPage()
      this.conf.pageSize = val;
      this.conf.currentPage = 1;
      this.$emit('onChange', this.conf);
      this.$forceUpdate();
    },
    handleCurrentChange (val) {
      this.conf.currentPage = val;
      this.$emit('onChange', this.conf);

    },
    turnFirstPage () {
      this.currentPage = 1;
    }
  },
  data () {
    return {
      currentPage: 1,
    };
  },
};
</script>