<template>
  <div class="frame-empty">
    <img src="~assets/images/icons/empty.svg" alt="" />
    <span>当前没有{{ info }}信息</span>
  </div>
</template>

<script>
export default {
  name: "Empty",
  props: {
    info: {
      type: String,
      default: "",
    },
  },
};
</script>