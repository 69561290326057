<template>
  <div class="case-area">
    <div class="case-bg"></div>
    <div class="frame">
      <type-filter :list="filterList"
                   @filterData="filterData" />
      <div class="case-content">
        <el-scrollbar>
          <div class="project-content"
               v-for="(n,i) in projectList"
               :key="i">
            <div class="project">
              <div class="project-img">
                <div class="project-img-frame">
                <el-badge value="收费" class="item" v-if="n.isCloneFree || n.isFree" style="margin-right:32px;">
                  <img :src="n.picture" style="margin-left:15px;"
                       alt="">
                </el-badge>
                <img :src="n.picture" style="margin-left:15px;" v-else
                       alt="">
                </div>
              </div>
              
              <span>{{n.name}}</span>
              <div class="project-shadow">
              <el-row>
                <el-button size="small"
                           @click="detail(n)">预览</el-button>
                <el-button size="small"
                           v-if="n.isClone"
                           type="primary"
                           @click="clonePrj(n)">克隆</el-button>
              </el-row>
            </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
  <ScreenPreview :prjId="selectProject.prjId"
                 :obj="selectProject"
                 :isShare="true"
                 v-if="previewTemp" />
</template>

<script>
import TypeFilter from 'components/common/Filter.vue';
import ScreenPreview from 'views/outerChain/screen/screenPreview';
import _ from 'underscore';
import Window from 'components/common/forms/Window';

export default {
  name: "Market",
  components: {
    TypeFilter,
    ScreenPreview,
    Window,
  },
  data () {
    return {
      projectList: [],
      recordData: '',
      previewTemp: false,
      selectProject: {
        id: ''
      },
      filterList: [],
    }
  },
  mounted () {
    this.getDataFn();
    this.shortcutKey();
  },
  methods: {
    getDataFn () {
      let self = this;
      self.previewTemp = false;
      self.filterList = [{
        id: 'all',
        name: '全部'
      }];
      self.$api.sp.getAllPublishProjects({ type: 2 }).then(data => {
        let uniq = [];
        self.projectList = data;
        self.projectList.forEach(v => {
          if (v.tags) {
            v.tags.forEach(t => {
              if (!_.contains(uniq, t.id)) {
                self.filterList.push({
                  id: t.id,
                  name: t.name
                })
                uniq.push(t.id);
              }
            })
          }

          v.picture = v.pic == '-1' ? "/img/project.88c4099e.jpg" : "/file/" + v.pic;
        })


        self.recordData = JSON.stringify(self.projectList);
      })
    },
    detail (item) {
      let self = this;
      self.$api.sp.getUserChargeRecord({
        verId: item.tags[0].verId,
        type: 2,
        chargeType: 0
      }).then(data => {
        if(data) {
          self.previewTemp = true;
          self.selectProject = item;
        }else {
          self.$message.error('该项目预览需要收费，请先付费！');
          return;
        }
      })
    },
    clonePrj(item) {
      let self = this;
      self.$api.sp.getUserChargeRecord({
        verId: item.tags[0].verId,
        type: 2,
        chargeType: 1
      }).then(data => {
        if(data) {
          self.selectProject = item;
          if(item.isCloneFree == 1) {
            self.$router.push({path: '/Mgt/ProjectsAdd', query: {type: 0, verId: item.tags[0].verId}})
          }else {
            self.$router.push({path: '/Mgt/ProjectsAdd', query: {type: 1, data: JSON.stringify(item)}})
          }
          
        }else {
          self.$message.error('该项目克隆需要收费，请先付费！');
          return;
        }
      })
    },
    shortcutKey () {
      let self = this;
      document.addEventListener('keyup', (event) => {
        //esc退出全屏预览
        if (event.which === 27) {
          self.previewTemp = false;
          event.preventDefault();
        }
      }, false);
    },
    filterData (id) {
      let self = this;
      self.projectList = JSON.parse(self.recordData);
      if (id != 'all') {
        self.projectList = _.filter(self.projectList, v => {
          let tag = _.find(v.tags, t => {
            return t.id == id;
          })
          return !!tag;
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@mixin gradualLine($color, $dir) {
  background: transparent;
  background-image: -webkit-linear-gradient(to $dir, $color);
  background-image: -moz-linear-gradient(to $dir, $color);
  background-image: -o-linear-gradient(to $dir, $color);
  background-image: linear-gradient(to $dir, $color);
}

.case-area {
  width: 100vw;
  height: calc(100vh - 54px);
  padding-bottom: 128px;
  background: transparent;
  position: relative;
  z-index: 10;

  .case-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-image: url("~assets/images/frame/bg-02.jpeg");

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
      $col: transparent 10%, rgba(10, 15, 20, 0.9) 100%;
      @include gradualLine($col, bottom);
      backdrop-filter: blur(24px);
      display: block;
    }
  }

  .case-content {
    width: 100%;
    height: calc(100vh - 240px);
    float: left;
  }
}

.project-content {
  width: 20%;
  float: left;
  padding: 16px;

  .project,
  .project-add {
    width: 100%;
    float: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.04);
    overflow: hidden;
    cursor: pointer;
    transition: all 0.1s linear;
    border-radius: 8px;
    position: relative;

    &:hover {
      border-color: rgba(30, 170, 255, 0.8);
      box-shadow: 0 0 20px rgba(30, 170, 255, 0.4);

      span {
        color: rgb(30, 170, 255);
      }

      .project-shadow {
        opacity: 1;
      }
    }

    .project-img {
      width: 100%;
      float: left;
      padding-bottom: calc(100% * 9 / 16);
      position: relative;

      .project-img-frame {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
        overflow: hidden;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        transition: all 0.1s linear;
      }
    }

    span {
      width: 100%;
      height: 40px;
      line-height: 40px;
      float: left;
      text-align: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 0 16px;
      transition: all 0.1s linear;
    }

    .project-shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 0.1s linear;
    }
  }

  .project {
    background: rgb(35, 65, 95);
    border: 0;

    &:hover {
      box-shadow: none;

      .project-img {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .project-add {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 16px;
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: rgb(30, 170, 255);

      img {
        opacity: 1;
      }
    }

    img {
      width: 36px;
      height: 36px;
      margin-right: 24px;
      opacity: 0.8;
      transition: all 0.1s linear;
    }
  }
}

@media (max-width: 1400px) {
  .project-content {
    width: 25%;
  }
}

@media (max-width: 960px) {
  .project-content {
    width: calc(100% / 3);
  }
}
</style>