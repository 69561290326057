<template>
  <div class="filter">
    <div class="filter-btn" v-for="(n, i) in list" :key="i" :class="{ 'active': i == currentIndex }" @click="btnClick(i)">
      {{ n.name }}</div>
  </div>
</template>

<script>
export default {
  name: "Filter",
  props: {
    list: {
      type: Array,
      default: [{ name: '全部', id: '-1' }]
    }
  },
  data () {
    return {
      currentIndex: 0,
    }
  },
  methods: {
    btnClick (i) {
      this.currentIndex = i;
      this.$emit('filterData', this.list[i].id);
    }
  }
}
</script>