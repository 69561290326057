<template>
  <div class="project-empty">
    <el-form ref="form" :model="project" :rules="rules">
      <el-row :gutter="24"
            v-if="template">
        <el-col :span="24">
          <el-form-item prop="template">
              <div class="insert-h">
                <div class="attr">模板</div>
                <div class="val">
                  <el-input maxlength="6"
                            v-model="template.name"
                            disabled></el-input>
                  <el-button type="primary"
                            style="margin-left:12px"
                            @click="changeTemplate()">更换模板</el-button>
                </div>
              </div>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24"
            v-if="authorization">
        <el-col :span="16" v-if="showUrl">
          <el-form-item prop="address">
            <div class="insert-h">
              <div class="attr">授权项目地址</div>
              <div class="val">
                <el-input placeholder="请输入授权地址"
                          v-model="project.address"></el-input>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="16" v-if="!showUrl">
          <el-form-item prop="address">
            <div class="insert-h">
              <div class="attr">项目名称</div>
              <div class="val">
                <el-input placeholder="请输入项目名称"
                            v-model="project.name"></el-input>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="code">
            <div class="insert-h">
              <div class="attr">授权码<p>（非必填）</p>
              </div>
              <div class="val">
                <el-input maxlength="10"
                          placeholder="请输入授权码"
                          v-model="project.code"></el-input>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="showUrl">
        <el-col :span="24">
          <el-form-item prop="name">
             <div class="insert-h">
                <div class="attr">项目名称</div>
                <div class="val">
                  <el-input placeholder="请输入项目名称"
                            v-model="project.name"></el-input>
                </div>
              </div>
          </el-form-item> 
        </el-col>
      </el-row>
        <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item prop="screenWidth">
            <div class="insert-h">
              <div class="attr">宽度</div>
              <div class="val">
                <el-input maxlength="5"
                          placeholder="大屏宽度（单位：px）"
                          v-model="project.screenWidth"></el-input>
                <p>px</p>
              </div>
            </div>
          </el-form-item> 
        </el-col>
        <el-col :span="8">
          <el-form-item prop="screenHeight">
            <div class="insert-h">
              <div class="attr">高度</div>
              <div class="val">
                <el-input maxlength="5"
                          placeholder="大屏高度（单位：px）"
                          v-model="project.screenHeight"></el-input>
                <p>px</p>
              </div>
            </div>
          </el-form-item> 
        </el-col>
        <el-col :span="8">
          <el-form-item prop="screenMode">
            <div class="insert-h">
              <div class="attr">模式</div>
              <div class="val">
                <el-select v-model="project.screenModeName"
                          placeholder="请选择大屏模式"
                          class="line"
                          @change="selectType">
                  <el-option v-for="item in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-form-item> 
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item prop="spaceName">
            <div class="insert-h">
              <div class="attr">所属空间</div>
              <div class="val">
                <el-dropdown style="width: 100%"
                            ref="dropdown"
                            trigger="click"
                            placement="bottom-start"
                            hide-on-click>
                  <el-input style="display: block; width: 100%"
                            v-model="project.spaceName"
                            placeholder="选择所属空间"
                            readonly="readonly"
                            class="my-select" />
                  <template #dropdown>
                    <el-dropdown-menu style="width: 720px; max-height: 200px">
                      <el-tree :data="spaceList"
                              hide-checkbox
                              :highlight-current="true"
                              :default-expand-all="true"
                              :props="defaultProps"
                              @node-click="selectNodeClick" />
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <el-button type="primary"
                          style="margin-left:12px"
                          @click="createSpace()">创建空间</el-button>
              </div>
            </div>
          </el-form-item> 
        </el-col>
      </el-row>
          
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item prop="description">
            <div class="insert-h">
              <div class="attr">项目描述<p>（非必填）</p>
              </div>
              <div class="val">
                <el-input type="textarea"
                          placeholder="请输入文本"
                          v-model="project.description"
                          :rows="4"></el-input>
              </div>
            </div>
          </el-form-item> 
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item>
            <div class="line align-center">
              <el-button @click="goLastPage()">取消</el-button>
              <el-button type="primary" @click="save()">创建项目</el-button>
            </div>
          </el-form-item> 
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup>
import { validateInteger } from 'common/validate';
import _ from 'underscore';
import { ref, onMounted, getCurrentInstance } from "vue";
import { useActions } from "components/util/mapActions";
import { useMutataions } from "components/util/mapMutations";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const props = defineProps({
  template: {
    type: Object,
    required: null
  },
  authorization: {
    type: Boolean,
    default: false
  },
  spaceList: {
    type: Array,
    default: []
  },
  spaceId: {
    type: String,
    default: '-1'
  },
  spaceName: {
    type: String,
    default: ''
  }
});
const emit = defineEmits(['changeTemplate', 'detailHide'])
const $router = useRouter();
const $route = useRoute();
const $store = useStore();
const storeActions = useActions("Global", ["getGlobalValues","getPageValues"]);
const storeMutations = useMutataions("Global", ["setSpOrPrj"]);
const $api = getCurrentInstance().appContext.config.globalProperties.$api;
const $message = getCurrentInstance().appContext.config.globalProperties.$message;

let defaultProps = ref({children: 'children', label: 'label'}), 
project = ref({
  name: "",
  screenWidth: 1920,
  screenHeight: 1080,
  screenMode: 0,
  screenModeName: '标准大小',
  spaceId: props.spaceId,
  spaceName: props.spaceName,
  description: "",
  address: "",
  code: ""
}), showUrl = ref(true), 
typeList = ref([{id: 0, name: '标准大小'},{id: 1, name: '拉伸至全屏'},{id: 2, name: '宽度铺满，高度按比例缩放'},{id: 3, name: '高度铺满，宽度按比例缩放'}]);
//ref对象
const dropdown = ref(null), form = ref(null);


const createSpace = () => {
  $router.push('SpaceAdd')
};

const changeTemplate = () => {
  emit('changeTemplate');
};

const goLastPage = () => {
  $router.go(-1);
};

const selectNodeClick = (item) => {
  project.value.spaceId = item.id;
  project.value.spaceName = item.name;
  dropdown.value && dropdown.value.handleClose();
};

const selectType = (val) => {
  project.value.screenMode = val;
};

const setupProject = (obj) => {
  project.value = {...obj};
  project.value.templateId = obj.tags[0].verId;
  project.value.type = 3;
  project.value.screenMode = obj.type;
  let mode = _.find(typeList.value, (v) => {
    return v.id == obj.type;
  })
  if(mode) 
    project.value.screenModeName = mode.name;
};

const setImportCode = (obj)=> {
  if(obj.id) {
    project.value.address = window.origin + '/ScreenPreview';
    project.value.templateId = obj.id;
    project.value.type = 2;
    showUrl.value = true;
  }
    
  if(obj.code)
    project.value.code = obj.code;
};

const save = () => {
  form.value && form.value.validate(valid => {
    if(valid) {
      if(project.value.address) {
        const url = new URL(project.value.address);
        project.value.shareId = url.searchParams.get('shareId');
      }
      $api.sp.addProject(project.value).then((res) => {
        if(res) {
          $message.success('操作成功');
          detail(res);
        }
      })   
    }
  })
};

const detail = (item) => {
  getAllPrjMember(item.id).then(() => {
    $api.sp.setCurrentPrjKey({ id: item.id }).then(res => {
      storeMutations.setSpOrPrj('project');
      storeActions.getGlobalValues();
      storeActions.getPageValues();
      $router.push('/Main/Preview');
    })
  })
  
};

const getAllPrjMember = (prjId) => {
  return new Promise((res, rej) => {
    $api.sp.getAllPrjMembers({ prjId: prjId }).then(data => {
      $store.commit("setProjectMember", { id: prjId, list: data });
      res();
    });
  });
};

const initData = () => {
  form.value && form.value.clearValidate();
  project.value = {
    name: "",
    screenWidth: 1920,
    screenHeight: 1080,
    screenMode: 0,
    spaceId: "-1",
    spaceName: "",
    description: "",
    address: "",
    code: "",
    screenModeName: '自适应高宽',
  };
};

const selectedSpace = (obj) => {
  project.value.spaceId = obj.spaceId;
  project.value.spaceName = obj.spaceName;
};

const changeShowUrl = (flag) => {
  showUrl.value = flag;
};

const rules = {
  name: [{required: true, message: '项目名称不能为空', trigger: 'blur'}],
  spaceName: [{required: true, message: '请选择所属空间', trigger: 'change'}],
  screenWidth: [{required: true, message: '宽度不能为空', trigger: 'blur'}, {validator: validateInteger, message: '请输入整数', trigger: 'blur'}],
  screenHeight: [{required: true, message: '宽度不能为空', trigger: 'blur'}, {validator: validateInteger, message: '请输入整数', trigger: 'blur'}],
}

defineExpose({
  initData,
  changeShowUrl,
  setImportCode,
  setupProject,
  selectedSpace
})

</script>

<style lang="scss" scoped>
.project-empty {
  width: 100%;
  float: left;
  max-width: 720px;
}
</style>