<template>
  <div class="dark">
    <div class="breadcrumb-area">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/Mgt/ProjectsMgt">项目管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增项目</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="frame-row">
      <div class="frame-col fixed"
           style="width:auto">
        <div class="add-left">
          <div class="add-link">请根据右侧面板信息快速构建一个项目，<span @click="jumpHelp()">操作文档</span></div>
        </div>
      </div>
      <div class="frame-col">
        <el-scrollbar>
          <div class="radio-content">
            <div class="tab-radio-group">
              <div class="tab-radio"
                   v-for="(n,i) in tabList"
                   :key="i"
                   :class="{'active':i == currentTab}"
                   @click="tabClick(i)">{{n}}</div>
            </div>
            <project-empty v-show="currentTab == 0" :spaceList="spaceList" ref="prjEmpty"></project-empty>
            <project-template ref="template" v-show="currentTab == 1" :spaceList="spaceList"></project-template>
            <project-import ref="import" v-show="currentTab == 2" :spaceList="spaceList"></project-import>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectEmpty from './childComps/ProjectEmpty.vue'
import ProjectTemplate from './childComps/ProjectTemplate.vue'
import ProjectImport from './childComps/ProjectImport.vue'
import _ from 'underscore'

export default {
  name: "ProjectsAdd",
  components: {
    ProjectEmpty,
    ProjectTemplate,
    ProjectImport,
  },
  mounted() {
    this.getSpaceList();
  },
  data () {
    return {
      tabList: ['空项目', '使用模板', '其他项目导入'],
      currentTab: '0',
      recordList: [],
      spaceList: []
    }
  },
  methods: {
    tabClick (i) {
      this.currentTab = i;
      if(i == 2) {
        this.$refs.prjEmpty.changeShowUrl(true);
      }
    },
    getSpaceList() {
      let self = this;
      self.currentTab = 0;
      self.$refs.prjEmpty.initData();
      if(self.$route.query.verId && self.$route.query.type && self.$route.query.type == 0) {
        self.currentTab = 2;
        self.$refs.import.getDataFn();
      }else if(self.$route.query.type && self.$route.query.type == 1) {
        self.currentTab = 1;
        self.$refs.template.useTemplate(JSON.parse(self.$route.query.data));
      }else if(self.$route.query.spaceId) {
        self.$refs.prjEmpty.selectedSpace(self.$route.query);
      }
      self.$api.sp.getAllSpaces().then((res) => {
        self.recordList = res;
        _.each(self.recordList, (v) => {
          v.label = v.name;
        })
        let root = _.filter(self.recordList, (v) => {
          return '-1' == v.pid;
        })
        if (root && root.length > 0) {
          _.each(root, (v) => {
            v = self.getChildren(v);
          })
        }
        self.spaceList = root;
      })
    },
    getChildren(obj) {
      let self = this;
      let children = _.filter(self.recordList, (v) => {
        return obj.id == v.pid;
      })
      if (children && children.length > 0) {
        obj.children = children;
        _.each(obj.children, (v) => {
          v = self.getChildren(v);
        })
      }
      return obj;
    },
    jumpHelp() {
      window.open('https://vls-docs.blueearth.com.cn/guide/quick-start/project.html')
    }
  }
}
</script>

<style lang="scss" scoped>
.dark {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;

  .frame-row {
    flex: 1;
  }
}

.add-left {
  width: 360px;
  height: calc(100vh - 240px);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 32px;

  .add-link {
    width: 100%;
    float: left;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.8);

    span {
      color: rgb(0, 130, 255);
      cursor: pointer;

      &:hover {
        color: rgb(30, 170, 255);
      }
    }
  }
}

.radio-content {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:after {
    content: "";
    width: 100%;
    height: 200px;
  }
}
</style>