<template>
  <div
    class="preview dark"
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="preview-header">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(n, i) in project.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame">
          <div class="line" style="padding: 24px 0 16px">
            <div class="right">
              <el-button type="primary" @click="dataModelCreate"
              v-if="addAuth"
                >新增</el-button
              >
            </div>
          </div>
          <el-table :data="tableData" style="width: 100%">
            <template #empty>
              <empty />
            </template>
            <el-table-column
              label="模型码"
              prop="code"
              min-width="20%"
            >
              <template #default="scope">
                <span style="cursor: pointer;color: #1eaaff;" @click="previewDetail(scope.row)">{{scope.row.code}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="模型码"
              prop="code"
              min-width="20%"
            ></el-table-column>
            <el-table-column label="资源类型" prop="typeName" min-width="20%" />
            <el-table-column
              label="执行类型"
              prop="actionName"
              min-width="20%"
            />
            <el-table-column
              label="创建时间"
              prop="createDate"
              min-width="20%"
            />
            <el-table-column label="操作" min-width="20%">
              <template #default="scope">
                <el-button
                  link
                  size="small"
                  v-if="editAuth"
                  @click="dataModelEdit(scope.row)" 
                >
                  编辑
                </el-button>
                <el-button link size="small" @click="del(scope.row)" v-if="delAuth">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="line pd-10">
            <page
              :total="conf.total"
              v-if="tableData.length"
              @onChange="onChange"
            ></page>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <el-drawer v-model="dataSource.isShow" direction="rtl" size="80%">
      <template #header>
        <h4>{{title}}</h4>
      </template>
      <template #default>
        <div class="line">
          <div class="line" v-if="modelData.step == 0">
            <div class="insert">
              <div class="attr star colon" style="width:112px">模型标识码</div>
              <div class="val">
                <el-input
                  v-model="modelData.code"
                  placeholder="模型标识码"
                  style="width:216px;height:38px;margin-top:3px;"
                  maxlength="8"
                />
              </div>
            </div>
            <div class="insert">
              <div class="attr star colon" style="width:112px">资源类型</div>
              <div class="val">
                <el-select
                  v-model="modelData.selectSourceId"
                  placeholder="请选择"
                  size="large"
                  style="width: 16%;"
                >
                  <el-option
                    v-for="item in modelData.sources"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
            </div>

            <div class="line" v-if="modelData.selectSourceId == 0">
              <div class="insert">
                <div class="attr star colon" style="width:112px">数据源</div>
                <div class="val">
                  <el-select
                    v-model="modelData.selectedDBId"
                    placeholder="请选择"
                    size="large"
                    style="width: 16%;"
                  >
                    <el-option
                      v-for="item in dbList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </div>
              </div>
              <div class="insert" v-if="modelData.selectedDBId">
                <div class="attr colon star" style="width:112px">执行方式</div>
                <div class="val">
                  <el-select
                    v-model="modelData.selectActions"
                    placeholder="请选择"
                    size="large"
                    @change="selectAction"
                  >
                    <el-option
                      v-for="item in modelData.actions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </div>
              <div
                class="line"
                v-if="modelData.selectActions == 0 && modelData.selectedDBId"
              >
                <div class="insert">
                  <div class="attr colon" style="width:112px">SQL</div>
                  <div class="val">
                    <v-ace-editor
                      ref="sql-data"
                      v-model:value="modelData.sql"
                      lang="json"
                      theme="tomorrow_night_blue"
                      :options="aceConfig.options"
                      :readonly="aceConfig.readOnly"
                      style="height: 250px"
                    />
                  </div>
                </div>
              </div>
              <div
                class="line"
                v-if="modelData.selectActions == 1 && modelData.selectedDBId"
              >
                <div class="insert">
                  <div class="attr star colon" style="width:112px">选择表</div>
                  <div class="val fix" style="width: 300px; margin-right: 10px">
                    <div class="frame-block">
                      <el-scrollbar max-height="500px">
                        <div class="list">
                          <div
                            class="list-line"
                            v-for="(n, i) in modelData.checkList"
                            :key="i"
                            :class="{ 'active': selectDB == n }"
                            @click="val => getTableDataFn(n, val)"
                          >
                            {{ n }}
                          </div>
                        </div>
                      </el-scrollbar>
                    </div>
                  </div>
                  <!--<div class="val" style="width: 100%">
                    <el-scrollbar max-height="500px">
                      <el-table
                        style="width: 100%"
                        @selection-change="handleSelectionChange"
                        :data="modelData.checkTable"
                        height="600"
                        v-if="modelData.checkTable.length > 0"
                      >
                        <el-table-column type="selection" width="55" />
                        <el-table-column
                          v-for="key in modelData.titleList"
                          :label="key"
                          :prop="key"
                          width="{{1/modelData.checkTable.length + '%'}}"
                        ></el-table-column>
                      </el-table>
                    </el-scrollbar>
                  </div>  -->
                </div>
                <div class="insert">
                  <div class="attr star colon" style="width:112px">
                    数据条数
                  </div>
                  <div class="val" style="margin-top: 5px;">
                    <el-radio-group v-model="modelData.sqlLimit" @change="setSqlLimit">
                      <el-radio :label="-1">不限制</el-radio>
                      <el-radio :label="1">仅一条</el-radio>
                    </el-radio-group>
                    <el-input
                      v-model="modelData.sqlDataLimit"
                      @input="setSqlLimit"
                      placeholder="条数"
                      style="width:5%;margin-left: 2%;margin-bottom: 3px;"
                      maxlength="3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="line" v-if="(modelData.selectSourceId == 1 && refresh)">
              <api 
                ref="requestApi"
                :config="apiConfig"></api> 
            </div>
            <div class="line" style="margin: 10px 0 0">
              <div class="insert">
                <div class="val">
                  <el-button type="primary" size="large" @click="next()"
                    >下一步</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="line" v-if="modelData.step == 1">
            <div class="insert">
              <div class="attr colon">选择类型</div>
              <div class="val">
                <el-radio-group
                  v-model="modelData.radio"
                  v-if="modelData.selectSourceId == 0"
                >
                  <el-radio :label="0" size="large">对象</el-radio>
                  <el-radio
                    :label="1"
                    size="large"
                    v-if="modelData.checkTable.length > 1"
                    >数组</el-radio
                  >
                </el-radio-group>
                <el-radio-group v-model="modelData.radio" v-else>
                  <el-radio :label="0" size="large">对象</el-radio>
                  <el-radio :label="1" size="large">数组</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="insert">
              <div class="attr colon">是否缓存</div>
              <div class="val">
                <el-switch
                  v-model="modelData.isCache"
                  inline-prompt
                  active-text="是"
                  inactive-text="否"
                  :active-value="1"
                  :inactive-value="0"
                  style="margin: 4px 0"
                />
              </div>
            </div>
            <div style="display: flex;">
              <div class="insert">
                <div class="attr colon">刷新时间</div>
                <div class="val">
                  <el-radio-group v-model="modelData.freshTime" @change="setFreshTime">
                    <el-radio :label="0">不设置</el-radio>
                  </el-radio-group>
                  <el-input
                    v-model="modelData.modelFreshTime"
                    placeholder=""
                    size="large"
                    @input="setFreshTime"
                    style="width: 100px; margin: 0 10px;"
                  />
                  <div class="word">秒</div>
                </div>
              </div>
              <div class="right">
                <el-button type="primary" @click="addEntityNode()"
                  >新增</el-button
                >
              </div>
            </div>
            <el-table
              :data="modelData.entities"
              style="width: 100%"
              height="600"
              :tree-props="{ children: 'children' }"
              row-key="id"
            >
              <el-table-column label="来源名称" min-width="20%">
                <template #default="scope">
                  <div class="line" style="padding-left: 20px">
                    <el-input v-model="scope.row.source" placeholder="来源名称" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="名称" min-width="20%">
                <template #default="scope">
                  <el-input v-model="scope.row.name" placeholder="名称" />
                </template>
              </el-table-column>
              <el-table-column label="数据类型" min-width="15%">
                <template #default="scope">
                  <el-select
                    v-model="scope.row.type"
                    placeholder="请选择"
                    size="large"
                  >
                    <el-option
                      v-for="item in modelData.fieldTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="简称" min-width="15%">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.abbreviation"
                    placeholder="简称"
                    maxlength="10"
                  />
                </template>
              </el-table-column>
              <el-table-column label="默认值" min-width="15%">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.defaultValue"
                    placeholder="默认值"
                  />
                </template>
              </el-table-column>
              <el-table-column label="描述" min-width="15%">
                <template #default="scope">
                  <el-input
                    v-model="scope.row.description"
                    placeholder="描述"
                  />
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="10%">
                <template #default="scope">
                  <el-button
                    link
                    size="small"
                    @click="addChildrenNode(scope.row)"
                  >
                    新增子节点
                  </el-button>
                  <el-button
                    link
                    size="small"
                    @click="delChildrenNode(scope.row)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <div class="line" style="margin: 10px 0 0">
              <div class="insert">
                <div class="val">
                  <el-button size="large" @click="prev()">返回</el-button>
                  <el-button type="primary" size="large" @click="next()"
                    >保存并退出</el-button
                  >
                </div> 
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-drawer>

    <el-drawer v-model="dataSource.isPreview" direction="rtl" size="80%">
      <template #header>
        <h4>{{title}}</h4>
      </template>
      <template #default>
        <div class="be-tabs-group">
          <div class="be-tab" @click="modelData.step = 0" :class="{active:modelData.step==0}">数据预览</div>
          <div class="be-tab" @click="modelData.step = 1" :class="{active:modelData.step==1}">结果集</div>
        </div>
            <div class="line" v-if="modelData.step == 0">
              <el-table
                :data="resultTable"
                border
                style="width: 100%"
              >
                <el-table-column :label="n.label" v-for="(n,i) in tabelTitles" :key="i" :prop="n.label">
                  
                  <el-table-column :label="c.label" v-if="n.isParent" v-for="(c, j) in n.children" :key="j" :prop="c.label">
                    
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
            <div class="line" v-if="modelData.step == 1">
              <el-table
                :data="modelData.entities"
                style="width: 100%"
                height="600"
                :tree-props="{ children: 'children' }"
                row-key="id"
              >
                <el-table-column label="来源名称" min-width="20%" prop="source" />
                <el-table-column label="名称" min-width="20%" prop="name" />
                <el-table-column label="数据类型" min-width="15%" prop="typeName" />
                <el-table-column label="简称" min-width="15%" props="abbreviation" /> 
                <el-table-column label="默认值" min-width="15%" props="defaultValue" />
                <el-table-column label="描述" min-width="15%" props="description" />
              </el-table>
            </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/ext-language_tools";
import "brace/theme/tomorrow_night_blue";
import "brace/mode/json";
import "brace/mode/javascript";
import Page from "components/common/forms/Page";
import Empty from 'components/common/forms/Empty';
import { prjBtnStatus, getSpaceOrPrjDetails, jumpRouteSetup} from "common/authBtn";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { ref, onMounted, getCurrentInstance, nextTick } from "vue";
import Api from 'components/content/api/Api'
import { ACCESS_TOKEN_KEY, COMMON_DES_KEY } from 'common/keys'

export default {
  name: "DataModel",
  components: {
    Page,
    VAceEditor,
    Api,
    Empty
  },
  setup(props, {emit}) {
    const $router = useRouter();
    let project = ref({}),
      tableData = ref([]),
      conf = ref({
        pageSize: 10,
        pageNum: 1,
        total: 10
      }),
      title = ref("新增数据模型"),
      loading = ref(true),
      addAuth = ref(false),
      editAuth = ref(false),
      delAuth = ref(false),
      dataSource = ref({
        isShow: false,
        isPreview: false
      }),
      aceConfig = ref({
        lang: "json", //解析json
        readOnly: false, //是否只读
        options: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          tabSize: 2,
          showPrintMargin: false,
          fontSize: 14
        }
      }),
      modelData = ref({
        entId: 0,
        code: "",
        step: 0,
        type: 1,
        selectSourceId: 0,
        sources: [
          { value: 0, label: "SQL" },
          { value: 1, label: "REST API" }
        ],
        selectActions: 0,
        actions: [
          { value: 0, label: "自定义SQL" },
          { value: 1, label: "选择表" }
        ],
        selectAPI: 0,
        requestTypes: [
          { value: 0, label: "GET" },
          { value: 1, label: "POST" }
        ],
        sql: "select * from xxx;",
        sqlLimit: -1,
        sqlDataLimit: "",
        url: "",
        header: "",
        params: "",
        proxy: 0,
        radio: 0,
        isCache: 0,
        radioList: [
          {
            name: "对象",
            check: true
          },
          {
            name: "数组",
            check: false
          }
        ],
        freshTime: 0,
        modelFreshTime: '',
        checkTable: [],
        code: "",
        constructData: null, //构造数据（数据源返回结果）
        entities: [],
        fieldTypes: [
          { value: 0, label: "文本" },
          { value: 1, label: "int" },
          { value: 2, label: "float" },
          { value: 3, label: "long" },
          { value: 4, label: "文件" },
          { value: 5, label: "图片" },
          { value: 6, label: "数组" },
          { value: 7, label: "对象" },
          { value: 8, label: "实体" },
          { value: 9, label: "时间" }
        ]
      }),
      dbList = ref([]),
      isEdit = ref(false),
      editList = ref([]),
      selectDB = ref(''), apiConfig = ref({
        headers: [{
          key: "",
          value: "",
          default: "",
        }],
        params: [{
          key: "",
          value: "",
          default: "",
        }],
        proxy: false,
        url: '',
        select: 'GET',
        paramType: 0,
        paramFormat: 1,
        dealFn: '(data) => {return data;}',
        preDealFn: '(headers, params) => {return {headers: headers, params: params}}',
        paramContent: '',
        paramDefault: '',
        result: ''
      }),
      refresh = ref(true), isSend = ref(false), resultTable = ref([]), tabelTitles = ref([]), global =ref(null), pageParams = ref(null);

    const requestApi = ref(null);
    //公共参数
    const store = useStore();
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const axios = getCurrentInstance().appContext.config.globalProperties.axios;
    const $message = getCurrentInstance().appContext.config.globalProperties
      .$message;
    const $confirm = getCurrentInstance().appContext.config.globalProperties
      .$confirm;
    const CancelToken = axios.CancelToken;
    let cancelFn;

    const getDataFn = () => {
      $api.sp
        .selectAllByPage({
          keyword: "",
          prjId: project.value.id,
          pageNum: conf.value.pageNum,
          pageSize: conf.value.pageSize
        })
        .then(data => {
          tableData.value = data.list;
          conf.value.total = data.total;
          tableData.value.forEach(v => {
            let source = _.find(modelData.value.sources, s => {
              return s.value == v.type;
            });
            if (source) {
              v.typeName = source.label;
              if (source.value == 0) {
                let action = _.find(modelData.value.actions, s => {
                  return s.value == v.actionType;
                });
                if (action) v.actionName = action.label;
              } else {
                let action = _.find(modelData.value.requestTypes, s => {
                  return s.value == v.actionType;
                });
                if (action) v.actionName = action.label;
              }
            }
          });
          getAllDBs();
        });
    };

    const getDetail = () => {
      getSpaceOrPrjDetails(store).then(res => {
        loading.value = false;
        project.value = res;
        prjBtnStatus(res.id, store, result => {
          addAuth.value = result.addAuth;
          editAuth.value = result.editAuth;
          delAuth.value = result.delAuth;
        });
        getDataFn();
      });
    };

    const dataModelCreate = () => {
      editList.value = [];
      selectDB.value = null;
      isSend.value = false;
      title.value = "新增数据模型";
      apiConfig.value = {
        headers: [{
          key: "",
          value: "",
          default: "",
        }],
        params: [{
          key: "",
          value: "",
          default: "",
        }],
        proxy: false,
        url: '',
        requestMethod: 'GET',
        paramType: 0,
        paramFormat: 1,
        dealFn: '(data) => {return data;}',
        preDealFn: '(headers, params) => {return {headers: headers, params: params}}',
        paramContent: '',
        paramDefault: '',
        result: ''
      };
      modelData.value = {
        entId: 0,
        code: "",
        step: 0,
        type: 1,
        selectSourceId: 0,
        sources: [
          { value: 0, label: "SQL" },
          { value: 1, label: "REST API" }
        ],
        selectActions: 0,
        actions: [
          { value: 0, label: "自定义SQL" },
          { value: 1, label: "选择表" }
        ],
        selectAPI: 0,
        requestTypes: [
          { value: 0, label: "GET" },
          { value: 1, label: "POST" }
        ],
        sql: "select * from xxx;",
        sqlLimit: -1,
        url: "",
        header: "",
        params: "",
        proxy: 0,
        radio: 0,
        isCache: 0,
        radioList: [
          {
            name: "对象",
            check: true
          },
          {
            name: "数组",
            check: false
          }
        ],
        freshTime: 0,
        modelFreshTime: '',
        checkTable: [],
        code: "",
        constructData: null, //构造数据（数据源返回结果）
        entities: [],
        fieldTypes: [
          { value: 0, label: "文本" },
          { value: 1, label: "int" },
          { value: 2, label: "float" },
          { value: 3, label: "long" },
          { value: 4, label: "文件" },
          { value: 5, label: "图片" },
          { value: 6, label: "数组" },
          { value: 7, label: "对象" },
          { value: 8, label: "实体" },
          { value: 9, label: "时间" }
        ]
      };
      isEdit.value = false;
      dataSource.value.isShow = true;
    };

    const dataModelEdit = obj => {
      title.value = "编辑数据模型";
      isSend.value = false;
      editList.value = [];
      let item = {
        entId: 0,
        id: obj.id,
        code: obj.code,
        step: 0,
        selectSourceId: obj.type,
        selectedDBId: obj.dataSourceId,
        selectActions: obj.actionType,
        selectAPI: obj.actionType,
        radio: obj.isObj,
        freshTime: obj.freshTime,
        sql: obj.sql,
        sqlLimit: obj.sqlLimit,
        url: obj.url,
        header: !obj.header ? "" : obj.header,
        params: !obj.params ? "" : obj.params,
        proxy: obj.proxy,
        isCache: !obj.isCache ? 0 : obj.isCache,
        constructData: obj.content != null ? JSON.parse(obj.content) : null,
        entities: obj.entities,
        modelFreshTime: obj.freshTime == 0 ? '' : ''+ obj.freshTime
      };
      modelData.value = { ...modelData.value, ...item };  
      apiConfig.value = {
        header: !obj.header ? [] : JSON.parse(obj.header),
        params: !obj.params ? [] : JSON.parse(obj.params),
        disableProxy: obj.proxy == 1,
        requestUrl: obj.url,
        requestMethod: obj.actionType == 0 ? 'GET' : 'POST',
        paramType: obj.paramType,
        paramFormat: obj.paramFormat,
        dealFn: obj.dealFn,
        preDealFn: obj.preDealFn,
        paramContent: obj.paramContent,
        paramDefault: obj.paramDefault,
        result: ''
      };
      refresh.value = false;
      nextTick(() => {
        refresh.value = true;
        isEdit.value = true;
        dataSource.value.isShow = true;
      })
    };

    const next = () => {
      if (modelData.value.step == 0) {
        if(!modelData.value.code) {
          $message.error("请先输入标识码");
          return;
        }
        if (modelData.value.selectSourceId == 0) {
          if (!modelData.value.selectedDBId) {
            $message.error("请先选择数据源");
            return;
          } 
          if (modelData.value.selectActions == 1) {
            if(selectDB.value == null) {
              $message.error("请先选择数据表");
              return;
            }
            modelData.value.entities = dealConstructData(
              modelData.value.constructData[0]
            );
            if (modelData.value.sqlLimit == 1) modelData.value.radio = 0;
          } else if (modelData.value.selectActions == 0) {
            if (modelData.value.sql == "select * from xxx;") {
              $message.error("请先输入sql");
              return;
            }
            gainSqlData();
          }
        } else if (modelData.value.selectSourceId == 1) gainApiData();
      }

      if (modelData.value.step == 1) {
        save();
        complete();
      }

      modelData.value.step++;
    };

    const prev = () => {
      modelData.value.step--;
    };

    const complete = () => {
      dataSource.value.isShow = false;
    };

    const onChange = param => {
      conf.value.pageSize = param.pageSize;
      conf.value.pageNum = param.currentPage;
      getDataFn();
    };

    const getAllDBs = () => {
      $api.sp
        .selectAllDBs({
          prjId: project.value.id
        })
        .then(res => {
          dbList.value = res;
        });
    };

    const selectAction = val => {
      modelData.value.selectActions = val;
      if (val == 1) {
        modelData.value.checkList = [];
        $api.sp
          .getAllTableNames({ dataSourceId: modelData.value.selectedDBId })
          .then(data => {
            modelData.value.checkList = data;
            if(data.length > 0) {
              selectDB.value = modelData.value.checkList[0]; 
              getTableDataFn(selectDB.value);
            } 
          });
      }
    };

    const getTableDataFn = (obj) => {
      selectDB.value = obj;

      modelData.value.checkTable = [];
      modelData.value.titleList = [];
      $api.sp
        .getDataByTable({
          dataSourceId: modelData.value.selectedDBId,
          tableName: obj,
          limit: modelData.value.sqlLimit
        })
        .then(data => {
          modelData.value.checkTable = data;
          modelData.value.constructData = data;
          for (let key in modelData.value.checkTable[0]) {
            modelData.value.titleList.push(key);
          }
        });
    };

    const handleSelectionChange = val => {
      modelData.value.constructData = [...val];
    };

    const dealConstructData = data => {
      let list = [];

      if (typeof data == "object") {
        modelData.value.entities = [];
        for (let key in data) {
          let type = getFieldType(data[key]);
          modelData.value.entId++;
          let obj = {
            id: modelData.value.entId,
            source: key,
            name: toHump(key),
            children: [],
            type: type,
            abbreviation: "",
            description: "",
            defaultValue: ""
          };
          if (type == 6) obj.children = dealConstructData(data[key][0]);
          else if (type == 7) obj.children = dealConstructData(data[key]);

          list.push(obj);
        }
      }

      return list;
    };

    //0: 文本；1：int； 2：float；3：long；4：文件；5：图片；6：数组；7：对象；8：实体；9：时间；
    const getFieldType = obj => {
      let type;
      switch (typeof obj) {
        case "string":
          type = 0;
          break;
        case "number":
          if (obj % 1 == 0) type = 1;
          else type = 2;
          break;
        case "object":
          if (_.isArray(obj)) type = 6;
          else if (obj instanceof Date) type = 9;
          else if (obj) type = 7;
          else type = 0;
          break;
        default:
          type = 0;
          break;
      }
      return type;
    };

    const save = () => {
      let model = modelData.value;
      if(model.radio == 0 && _.isArray(model.constructData)) {
        model.constructData = model.constructData[0];
      }

      if(model.radio == 1 && !_.isArray(model.constructData)) {
        let list = [];
        list.push(model.constructData);
        model.constructData = list;
      }
      if (!isEdit.value) {
        let args = {
          code: model.code,
          type: model.selectSourceId,
          actionType:
            model.selectSourceId == 0 ? model.selectActions : model.selectAPI,
          dataSourceId: model.selectedDBId,
          content: JSON.stringify(model.constructData),
          prjId: project.value.id,
          isObj: model.radio,
          freshTime: parseInt(model.freshTime),
          entities: model.entities,
          sql: model.sql,
          url: model.url,
          header: model.header,
          params: model.params,
          proxy: model.proxy ? 1: 0,
          isCache: model.isCache,
          dealFn: model.dealFn,
          preDealFn: model.preDealFn,
          paramContent: model.paramContent,
          paramDefault: model.paramDefault,
          sourceData: model.sourceData,
          paramType: model.paramType,
          paramFormat: model.paramFormat,
          sqlLimit: model.sqlLimit
        };
        $api.sp.addModel(args).then(data => {
          $message.success("操作成功!");
          getDataFn();
        });
      } else {
        let args2 = {
          id: model.id,
          content: JSON.stringify(model.constructData),
          prjId: project.value.id,
          isObj: model.radio,
          freshTime: parseInt(model.freshTime),
          entities: model.entities,
          code: model.code,
          type: model.selectSourceId,
          actionType:
            model.selectSourceId == 0 ? model.selectActions : model.selectAPI,
          dataSourceId: model.selectedDBId,
          sql: model.sql,
          url: model.url,
          header: model.header,
          params: model.params,
          proxy: model.proxy ? 1: 0,
          isCache: model.isCache,
          dealFn: model.dealFn,
          preDealFn: model.preDealFn,
          paramContent: model.paramContent,
          paramDefault: model.paramDefault,
          sourceData: model.sourceData,
          paramType: model.paramType,
          paramFormat: model.paramFormat,
          sqlLimit: model.sqlLimit
        };
        $api.sp.updateModel(args2).then(data => {
          $message.success("操作成功!");
          getDataFn();
        });
      }
    };

    const del = obj => {
      $confirm("此操作将永久删除该模型记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        $api.sp.deleteModel({ id: obj.id }).then(res => {
          $message.success("删除成功!");
          getDataFn();
        });
      });
    };

    const gainSqlData = () => {
      modelData.value.constructData = null;
      $api.sp
        .getDBChartData({
          databaseSourceId: modelData.value.selectedDBId,
          sql: modelData.value.sql
        })
        .then(data => {
          let obj;
          if (_.isArray(data)) {
            modelData.value.radio = 1;
            obj = data[0];
          } else {
            obj = data;
            if (typeof data == "object") modelData.value.radio = 0;
          }

          modelData.value.constructData = data;
          modelData.value.entities = dealConstructData(obj);
        });
    };

    const gainApiData = () => {
      if(requestApi.value) {
        let res = requestApi.value.returnConfig();
        modelData.value = {...modelData.value, ...{
          url: res.requestUrl,
          header: res.requestHeaders,
          params: res.requestParams,
          proxy: res.disableProxy,
          dealFn: res.dealFn,
          preDealFn: res.preDealFn,
          paramContent: res.paramContent,
          paramDefault: res.paramDefault,
          sourceData: res.sourceData,
          paramType: res.paramType,
          paramFormat: res.paramFormat,
          selectAPI: res.requestMethod == 'GET' ? 0 : 1
        }}
        modelData.value.constructData = null;
        if(res.result) {
          let result = JSON.parse(res.result);
          let obj;
          if (_.isArray(result)) {
            obj = result[0];
            modelData.value.radio = 1;
          } else {
            obj = result;
            if (typeof result == "object") modelData.value.radio = 0;
          }
          modelData.value.constructData = result;
          modelData.value.entities = dealConstructData(obj);
        }
        
      }
    };

    const toHump = key => {
      if (key.includes("_")) {
        let oarr = key.split("_");
        for (let i = 1; i < oarr.length; i++) {
          oarr[i] = oarr[i].charAt(0).toUpperCase() + oarr[i].slice(1);
        }
        return oarr.join("");
      }
      return key;
    };

    const addEntityNode = () => {
      modelData.value.entId++;
      modelData.value.entities.push({
        id: modelData.value.entId,
        source: "",
        name: "",
        children: [],
        type: 0,
        abbreviation: "",
        description: "",
        defaultValue: ""
      });
    };

    const addChildrenNode = obj => {
      modelData.value.entId = modelData.value.entId + 1;
      obj.children.push({
        id: modelData.value.entId,
        source: "",
        name: "",
        children: [],
        type: 0,
        abbreviation: "",
        description: "",
        defaultValue: ""
      });
    };

    const delChildrenNode = obj => {
      modelData.value.entities = getDeepNode(modelData.value.entities, obj.id);
    };

    const getDeepNode = (list, id) => {
      let obj = _.find(list, v => {
        return v.id == id;
      });
      if (obj) {
        list = _.filter(list, v => {
          return v.id != id;
        });
      } else {
        list.forEach(v => {
          if (v.children.length > 0) {
            v.children = getDeepNode(v.children, id);
          }
        });
      }
      return list;
    };

    const setSqlLimit = val => {
      modelData.value.sqlLimit = parseInt(val);
      getTableDataFn(selectDB.value);
    };

    const selectSqlTable = obj => {
      selectDB.value = obj;
    };

    const setFreshTime = val => {
      modelData.value.freshTime = parseInt(val);
      if(modelData.value.freshTime == 0)
        modelData.value.modelFreshTime = '';
    };

    const jumpSpace = (n) => {
      if(project.value.id != n.id) {
        jumpRouteSetup(emit, n).then(() => {
          $router.push('/Main/SpaceDetail');
        })
      } 
    };

    const previewDetail = async (obj) => {
      obj = {
        ...obj, 
        ...{
          headers: !obj.header ? [] : JSON.parse(obj.header),
          params: !obj.params ? [] : JSON.parse(obj.params),
          disableProxy: obj.proxy == 1,
          requestMethod: obj.actionType == 0 ? 'GET' : 'POST',
          result: ''
        }
      };
      title.value = "查看";
      let info = await sendRequest(obj);
      adaptModelData(info);
      modelData.value = {...modelData.value, ...info};
      dataSource.value.isPreview = true;
    };

    const sendRequest = (request) => {
      return new Promise((resolve, rej) => {
        let header = constructData(request.headers),
          params = constructData(request.params);

        let newUrl = adaptParams(request.url);
        let pFn = adaptParams(request.preDealFn);
        let dFn = adaptParams(request.dealFn);
        let preFn = eval(pFn),
          dealFn = eval(dFn);
        let result = preFn(header, params);
        (header = result.headers), (params = result.params);

        if (request.actionType == 1) {
          let pContent = adaptParams(request.paramContent),
            pDefault = adaptParams(request.paramDefault);

          if (pContent) {
            let cont, def;
            try {
              if (pContent)
                cont = JSON.parse(pContent);
              if (pDefault)
                def = JSON.parse(pDefault);
            } catch (error) {
              if (pContent) {
                cont = eval(`
                let cont=${pContent};
                cont;
                `);
              }


              if (pDefault) {
                def = eval(`
                let def=${pDefault};
                def;
                `);
              }

            }

            let pcObj = dealParamContent(cont, def);
            params = pcObj;
          }
        }


        if (!request.proxy) {
          if (request.actionType == 0) {
            let url = newUrl;
            for (let key in params) {
              if (url.indexOf("?") != -1) {
                url = url + "&" + key + "=" + params[key];
              } else {
                url = url + "?" + key + "=" + params[key];
              }
            }
            axios.get(url, {
              headers: {...header},
              
            }).then(data => {
              request.sourceData = JSON.stringify(data.data, null, "\t");
              let res = dealFn(data.data);
              request.result = JSON.stringify(res, null, "\t");
              resolve(request);
            });
          } else if (request.actionType == 1) {
            axios.post(newUrl, params, {
              ...header, cancelToken: new CancelToken((c) => {
                cancelFn = c;
              })
            }).then(data => {
              request.sourceData = JSON.stringify(data.data, null, "\t");
              let res = dealFn(data.data);
              request.result = JSON.stringify(res, null, "\t");
              resolve(request);
            });
          }
        } else {
          axios.post('/sp/project/httpClientInterface', {
            type: request.select,
            url: newUrl,
            params: JSON.stringify(params),
            header: JSON.stringify(header)
          }, {
            headers: {
              Authorization: getToken(),
            },
            cancelToken: new CancelToken((c) => {
              cancelFn = c;
            })
          }).then(data => {
            request.sourceData = JSON.stringify(data.data.obj, null, "\t");
            let res = JSON.parse(data.data.obj);
            res = dealFn(res);
            request.result = JSON.stringify(res, null, "\t");
            request.result = dealFn(request.result);
            resolve(request);
          });
        }
      })
      
    };

    const getToken = () => {
      const accessToken = Tools.getLocal(COMMON_DES_KEY, ACCESS_TOKEN_KEY);
      if (!accessToken) {
        return '';
      }
      return `Bearer ${accessToken}`;
    }

    const dealParamContent = (data, def) => {
      let result = {};
      if (def) {
        for (let key in def) {
          result[key] = def[key];
        }

        for (let key2 in data) {
          if (!data[key2] && def[key2])
            result[key2] = def[key2];
          else
            result[key2] = data[key2];
        }
      } else {
        result = data;
      }

      return result;
    };

    const constructData = data => {
      let result = {};
      data.forEach(v => {
        let pKey = adaptParams(v.key);
        let pValue = adaptParams(v.value);
        let pDefault = adaptParams(v.default);
        if (pKey) result[pKey] = !pValue ? pDefault : pValue;
      });

      return result;
    };

    const adaptParams = (data) => { 
      //匹配@() 参数
      let matches = data.match(/\@\((.+?)\)/gi);
      //匹配到
      if (matches) {
        let obj = {};
        matches.forEach(p => {
          //获取key
          let key = p.replace('@(', '');
          key = key.replace(')', '');
          let args = key.split(".");

          //全局参数
          let gValue = _.find(global.value, cp => {
            return cp.key == key;
          });
          if (gValue) {
            if (gValue.value == "true" || gValue.value == "false")
              gValue.value = !!gValue.value;
            obj[p] = gValue.value;
          }

          //页面参数
          if (args.length > 1) {
            for (let sheet in pageParams.value) {
              if (args[0] == sheet) {
                let pValue = _.find(pageParams.value[sheet], (cp) => {
                  return cp.key == args[1];
                })
                if (pValue) {
                  if (pValue.value == 'true' || pValue.value == 'false')
                    pValue.value = !!pValue.value;
                  obj[p] = pValue.value;
                }
              }
            }
          }
        })

        //替换数据
        for (let key in obj) {
          if (data.indexOf(`"${key}"`) != -1 && typeof obj[key] == "boolean")
            data = data.replaceAll(`"${key}"`, obj[key]);

          data = data.replaceAll(key, obj[key]);
        }
      }
      return data;
    };

    const adaptModelData = obj => {
      let content = JSON.parse(obj.content),
        result = null;
      if (obj.isObj == 0) {
        if (_.isArray(content)) {
          result = { ...content[0] };
        } else if (typeof content == "object") {
          result = { ...content };
        }
      } else {
        if (_.isArray(content)) {
          result = [...content];
        } else if (typeof content == "object") {
          result = [];
          result.push(content);
        }
      }

      result = replaceModelEntity(result, obj.entities);
      tabelTitles.value = getTableTitles(result);
      let keys = [];
      getResultData(tabelTitles.value, keys);
      resultTable.value = [];
      consistResult("", result, {}, resultTable.value);
      
    };

    const replaceModelEntity = (list, entities) => {
      if (_.isArray(list)) {
        list.forEach(v => {
          if (typeof v == "object" && !_.isArray(v)) {
            for (let key in v) {
              entities.forEach(e => {
                if (e.source == key) {
                  v[e.name] = v[key];
                  if (e.source != e.name) Reflect.deleteProperty(v, key);
                  if (typeof v[e.name] == "object") {
                    v[e.name] = replaceModelEntity(v[e.name], e.children);
                  }
                }
              });
            }
          } else if (_.isArray(v)) {
            v.forEach(o => {
              if (typeof o == "object" && !_.isArray(o)) {
                for (let key2 in o) {
                  entities.forEach(e => {
                    if (e.source == key2) {
                      o[e.name] = o[key2];
                      if (e.source != e.name) Reflect.deleteProperty(o, key2);
                      if (typeof o[e.name] == "object") {
                        o[e.name] = replaceModelEntity(o[e.name], e.children);
                      }
                    }
                  });
                }
              }
            });
          } else {
            entities.forEach(e => {
              if (e.source == v) {
                v[e.name] = v;
              }
            });
          }
        });
      } else if (typeof list == "object") {
        for (let key1 in list) {
          entities.forEach(e => {
            if (e.source == key1) {
              list[e.name] = list[key1];
              if (e.source != e.name) Reflect.deleteProperty(list, key1);
              if (typeof list[e.name] == "object") {
                list[e.name] = replaceModelEntity(list[e.name], e.children);
              }
            }
          });
        }
      }
      return list;
    };

    const getTableTitles = (data) => {
      let keys = [];
      if(typeof data == 'object' && !_.isArray(data)) {
        for (let key in data) {
          let keyObj = {
            label: key
          }
          if (typeof data[key] == "object" && !_.isArray(data[key])) {
            keyObj.isParent = true;
            keyObj.children = getTableTitles(data[key]);
          
          }else if(_.isArray(data[key])){
            if(data[key].length > 0) {
              let children = getTableTitles(data[key][0]);
              if(typeof data[key][0] !== 'object') {
                keyObj.isParent = false;
                keyObj = {...keyObj, ...children[0]}
              }else {
                keyObj.isParent = true;
                keyObj.children = children;
              }
              
            }
          }else {
            keyObj = {
              label: key,
              prop: data[key],
              isParent: false
            }
          }

          keys.push(keyObj)
        }
      }else if(_.isArray(data)){
        if(data.length > 0) {
          keys = getTableTitles(data[0]);
        }
      }else {
        keys = [{
          prop: data,
          isParent: false
        }]
      }
      
      return keys;
    };

    const getResultData = (list, data) => {
      list.map(v => {
        if(!v.isParent) {
          data.push(v);
        }else {
          getResultData(v.children, data);
        }
      })
    };

    const consistResult = (pkey, data, obj, list) => {
      if(typeof data == 'object' && !_.isArray(data)) {
        let containObj = false;
        for(let key in data) {
          if(typeof data[key] == 'object' && data[key]) {
           containObj = true;
           break;
          }else {
            obj[key] = data[key];
          }
        }
        if(containObj) {
          for(let key in data) {
            if(typeof data[key] == 'object' && data[key]) {
              consistResult(key, data[key], obj, list);
            }
          }
        }else {
          list.push(JSON.parse(JSON.stringify(obj)));
        }
       
      }else if(_.isArray(data)) {
        let containObj = data.find(e => {return typeof e == 'object' && e});
        if(containObj) {
          data.map(e => {
            consistResult(pkey, e, obj, list);
          })
        }else {
          data.map(e => {
            obj[pkey] = e;
            list.push(JSON.parse(JSON.stringify(obj)));
          })
        }
        
      }else {
        obj[pkey] = data;
        list.push(obj);
      }
    };

    onMounted(() => {
      emit("setCommonParams", () => {
        if(!global.value) {
          global.value = store.state.Global.globalValues;
        }
        if(!pageParams.value) {
          pageParams.value = store.state.Global.pageValues;
        }
        getDetail();
      });
      
    });

    return {
      resultTable,
      addAuth,
      editAuth,
      delAuth,
      loading,
      dataSource,
      project,
      tableData,
      modelData,
      aceConfig,
      dbList,
      conf,
      isEdit,
      selectDB,
      title,
      apiConfig,
      requestApi,
      refresh,
      isSend,
      tabelTitles,

      dataModelCreate,
      next,
      prev,
      complete,
      onChange,
      selectAction,
      getTableDataFn,
      handleSelectionChange,
      dataModelEdit,
      del,
      addChildrenNode,
      delChildrenNode,
      addEntityNode,
      setSqlLimit,
      selectSqlTable,
      setFreshTime,
      jumpSpace,
      previewDetail,
    };
  }
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;
  float: left;


  .list-line {
    width: 100%;
    float: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
    padding: 8px;
    line-height: 1.35;
    word-break: break-all;
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 1);
    }

    &.active{
      background: rgba(0, 130, 255, .5);
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
