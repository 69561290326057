<template>
  <div class="preview dark" v-loading="loading" element-loading-text="加载中..." :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header dark">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(n, i) in project.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame dark">
          <!-- <div class="line"
               style="padding:24px 0">
            <div class="right">
              <el-button type="primary"
                         @click="versionCreate()">新增版本</el-button>
            </div>
          </div>-->
          <div class="line">
            <el-card class="box-card" v-for="(n, i) in list" :key="i">
              <template #header>
                <div class="card-header" style="display: flex;">
                  <span>{{ n.name }}</span>
                  <el-tooltip class="box-item" effect="dark" :content="getCheckStatusName(n, 1)" placement="top">
                    <span>
                      <el-tag type="warning" class="my-tag mx-1" effect="dark" closable round v-if="n.isCase"
                        @close="handleClose(n, 1)">
                        <b>案例</b>
                        <div :class="getCheckStatusClass(n, 1)" @click="detailShow(n, 1)"></div>
                      </el-tag>
                    </span>
                  </el-tooltip>
                  <el-tooltip class="box-item" effect="dark" :content="getCheckStatusName(n, 2)" placement="top">
                    <span>
                      <el-tag type="warning" class="my-tag mx-1" effect="dark" closable round v-if="n.isMarket"
                        @close="handleClose(n, 2)"><b>市场</b>
                        <div :class="getCheckStatusClass(n, 2)" @click="detailShow(n, 2)"></div>
                      </el-tag>
                    </span>
                  </el-tooltip>
                  <div class="right">
                    <el-tooltip class="box-item" effect="dark" content="案例申诉" transition="el-toggle" :hide-after="0"
                      placement="top" v-if="n.isCase && (n.caseStatus == 1 || n.caseStatus == 3)">
                      <div class="icon-btn icomoon-box-remove" @click="showAppealRecord(n, 1)"></div>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="市场申诉" transition="el-toggle" :hide-after="0"
                      placement="top" v-if="n.isMarket && (n.marketStatus == 1 || n.marketStatus == 3)">
                      <div class="icon-btn icomoon-box-remove" @click="showAppealRecord(n, 2)"></div>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="编辑" transition="el-toggle" :hide-after="0"
                      placement="top" v-if="editAuth">
                      <div class="icon-btn icomoon-pencil" @click="versionEdit(i, list)"></div>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="分享" transition="el-toggle" :hide-after="0"
                      placement="top" v-if="editAuth">
                      <div class="icon-btn icomoon-share2" @click="shareShow(n)"></div>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" :show-after="2" content="发布案例" transition="el-toggle"
                      :hide-after="0" placement="top" v-if="isAdmin && !n.isCase && n.marketStatus == 2">
                      <div class="icon-btn icomoon-new-tab" @click="applyCase(n)"></div>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="发布市场" transition="el-toggle" :hide-after="0"
                      placement="top" v-if="editAuth">
                      <div class="icon-btn icomoon-share" @click="tagShow(n, 2)"></div>
                    </el-tooltip>
                    <!-- <el-tooltip class="box-item" effect="dark" content="发布模板" transition="el-toggle" :hide-after="0"
                      placement="top">
                      <div class="icon-btn icomoon-newspaper" @click="tagShow(n, 3)"></div>
                    </el-tooltip> -->
                    <el-tooltip class="box-item" effect="dark" content="回退版本" transition="el-toggle" :hide-after="0"
                      placement="top" v-if="editAuth">
                      <div class="icon-btn icomoon-loop" @click="switchVersion(i, list)"></div>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="全屏" transition="el-toggle" :hide-after="0"
                      placement="top">
                      <div class="icon-btn icomoon-enlarge" @click="getVerScreen(n)"></div>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="删除" placement="top" v-if="delAuth">
                      <div class="icon-btn icomoon-bin" @click="deleteVersion(n)"></div>
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <pre>{{ n.description }}</pre>
              <div class="pic-area">
                <el-image :src="'/file/' + p" v-for="(p, j) in n.fileIds" :key="j" :preview-src-list="n.srcList"
                  :initial-index="n.srcList.length" alt="图片" style="width: 20%;" />
              </div>
              <div class="line" style="padding:2px">
                <div class="card-info">
                  <i class="icomoon-bookmark"></i>{{ n.no }}
                  <i class="icomoon-clock2"></i>{{ n.createDate }}
                  <img :src="n.icon" alt="logo" style="margin-bottom:-4px;" class="card-logo">
                </div>
              </div>
            </el-card>
          </div>
          <empty v-if="list.length == 0" info="版本信息" style="height:70vh"></empty>
          <div class="line pd-10">
            <page :total="conf.total" v-show="list.length" @onChange="onChange"></page>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <window windowId="edit-version" :shadow="true" class="dark" :title="version.title" @windowHide="versionHide"
      v-show="version.isShow">
      <template #body>
        <div class="line pd-20">
          <el-form ref="form" :model="version.info" :rules="rules">

            <el-form-item prop="name">
              <div class="insert-h">
                <div class="attr">版本名称</div>
                <div class="val">
                  <el-input v-model="version.info.name" placeholder="请输入参数名" />
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="description">
              <div class="insert-h">
                <div class="attr">描述(非必填)</div>
                <div class="val">
                  <el-input v-model="version.info.description" placeholder="请输入变量值" type="textarea" :rows="4" />
                </div>
              </div>
            </el-form-item>

            <el-form-item prop="no">
              <div class="insert-h">
                <div class="attr">版本号</div>
                <div class="val">
                  <el-input v-model="version.info.no" placeholder="请输入版本号" />
                </div>
              </div>
            </el-form-item>

            <div class="insert-h">
              <div class="attr">上传封面(非必填)</div>
              <div class="val">
                <el-upload action="#" list-type="picture-card" :file-list="files" :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove" :http-request="handleAvatarSuccess">
                  <el-icon class="icomoon-plus"></el-icon>
                </el-upload>
              </div>
            </div>
          </el-form>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="versionHide()">取消</el-button>
          <el-button type="primary" @click="versionSave()">保存</el-button>
        </div>
      </template>
    </window>

    <window :title="tag.title" :shadow="true" class="dark" @windowHide="tagClose()" windowId="version-tag"
      v-show="tag.isShow">
      <template #body>
        <div class="line pd-20">
          <div class="insert-h">
            <div class="attr">为该版本添加标签</div>
            <div class="val">
              <div class="tag-default-group">
                <el-scrollbar>
                  <div class="tag-default" v-for="(n, i) in tag.defaultList" :key="i" @click="addTag(n)">{{ n.name }}
                  </div>
                </el-scrollbar>
              </div>
            </div>
            <!-- <div class="val">
              <el-input v-model="tag.value"
                        placeholder="添加自定义标签" />
              <el-button size="small"
                         style="margin-left:8px"
                         type="primary"
                         @click="createTag()">添加</el-button>

            </div> -->
            <div class="val">
              <div class="tag-area">
                <div class="tag" v-for="(n, i) in tag.list" :key="i">
                  <span>{{ n.name }}</span>
                  <div class="tag-close" @click="removeTag(i)"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="insert-h">
            <div class="attr">上传封面(非必填)
              <el-popover trigger="click" placement="bottom-start" effect="dark" :width="400">
                <template #reference>
                  <div class="insert-link" style="margin-left:12px">历史封面<i class="icomoon-play3"></i></div>
                </template>
                <div class="insert-link-img" v-for="(m, j) in filesHistory" :key="j" @click="chooseCover(m)">
                  <img :src="m.url" alt="" />
                </div>
              </el-popover>
            </div>
            <div class="val">
              <el-upload action="#" list-type="picture-card" :file-list="coverFiles"
                :on-preview="handlePictureCardPreview" :on-remove="handleCoverRemove" :http-request="handleConverSuccess"
                preview-src-list="coverFiles">
                <el-icon class="icomoon-plus"></el-icon>
              </el-upload>
            </div>
          </div>
          <el-row :gutter="24">
            <el-col :span="11">
              <div class="insert-h" v-if="selectVerType == 2">
                <div class="attr">预览收费</div>
                <div class="val">
                  <el-switch v-model="market.isFree" :active-value="1" :inactive-value="0" @change="changeFree"
                    inline-prompt active-text="是" inactive-text="否" />
                </div>
              </div>
              <div class="insert-h" v-if="selectVerType == 2">
                <div class="attr">克隆</div>
                <div class="val">
                  <el-switch v-model="market.isClone" :active-value="1" :inactive-value="0" @change="changeCloneFree"
                    inline-prompt active-text="是" inactive-text="否" />
                </div>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="11">
              <div class="insert-h" v-if="selectVerType == 2">
                <div class="attr">价格</div>
                <div class="val">
                  <el-input-number v-model="market.price" placeholder="价格" size="small" :disabled="market.isFree == 0"
                    :precision="2" :step="0.1" :max="10" :min="0" />
                </div>
              </div>
              <div class="insert-h" v-if="selectVerType == 2 && market.isClone == 1">
                <div class="attr">收费</div>
                <div class="val">
                  <el-switch v-model="market.isCloneFree" :active-value="1" :inactive-value="0" inline-prompt
                    active-text="是" inactive-text="否" />
                </div>
              </div>
              <div class="insert-h" v-if="selectVerType == 2 && market.isClone == 1 && market.isCloneFree == 1">
                <div class="attr">价格</div>
                <div class="val">
                  <el-input-number v-model="market.clonePrice" placeholder="价格" size="small"
                    :disabled="market.isCloneFree == 0" :precision="2" :step="0.1" :max="10" :min="0" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button size="small" @click="tagClose()">取消</el-button>
          <el-button size="small" type="primary" @click="publishVersionData()">确定</el-button>
        </div>
      </template>
    </window>

    <window :title="authCode.title" :shadow="true" class="dark" @windowHide="shareHide()" windowId="version-share"
      v-show="authCode.isShow">
      <template #body>
        <div class="line pd-20">
          <div class="insert-h">
            <div class="attr">有效时间</div>
            <div class="val">
              <el-radio-group v-model="authCode.info.expireType" @change="changeExpire">
                <el-radio label="hour1">1小时</el-radio>
                <el-radio label="day1">1天</el-radio>
                <el-radio label="day3">3天</el-radio>
                <el-radio label="day7">7天</el-radio>
                <el-radio label="day14">14天</el-radio>
                <el-radio label="forever">永久</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="insert-h">
            <div class="attr">授权码</div>
            <div class="val" style="width:25%;">
              <el-input v-model="authCode.info.code" maxlength="6" placeholder="请输入授权码" />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr">克隆</div>
            <div class="val">
              <el-switch v-model="authCode.info.type" inline-prompt :active-value="1" :inactive-value="0" active-text="是"
                inactive-text="否" style="margin:4px 0" />
            </div>
          </div>
          <div class="insert-h" v-if="authCode.info.type">
            <div class="attr">授权码</div>
            <div class="val" style="width:25%;">
              <el-input v-model="authCode.info.cloneCode" maxlength="6" placeholder="请输入授权码" />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button size="small" @click="shareHide()">取消</el-button>
          <el-button size="small" type="primary" @click="saveShareCode()">确定</el-button>
        </div>
      </template>
    </window>

    <window windowId="share-window" :shadow="true" class="dark" title="分享链接" @windowHide="shareUrlHide"
      v-show="authCode.share.show">
      <template #body>
        <div class="line pd-20">
          <div class="insert-h">
            <div class="attr colon">链接地址</div>
            <div class="val">
              <el-input v-model="authCode.share.url" disabled />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr colon">时效</div>
            <div class="val" style="width:25%;">
              <el-input v-model="authCode.info.expireName" disabled />
            </div>
          </div>
          <div class="insert-h">
            <div class="attr colon">授权码</div>
            <div class="val" style="width:25%;">
              <el-input v-model="authCode.info.code" disabled />
            </div>
          </div>
          <div class="insert-h" v-if="authCode.info.type == 1">
            <div class="attr colon">克隆码</div>
            <div class="val" style="width:25%;">
              <el-input v-model="authCode.info.cloneCode" disabled />
            </div>
          </div>

        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button type="primary" @click="copy()">复制</el-button>
        </div>
      </template>
    </window>

    <window windowId="life-cycle" :shadow="true" class="dark" title="申诉详情" @windowHide="detailHide"
      v-show="detail.isShow">
      <template #body>
        <div class="line pd-20">
          <el-timeline class="my-timeline">
            <el-timeline-item v-for="(item, index) in detail.list" :key="index" :color="item.color" placement="top"
              :timestamp="item.createDate">
              <el-card>
                <div class="opt-layet">
                  <img :src="item.head" :title="item.createUser" alt="">
                  <h4>{{ item.statusName }}</h4>
                  <p>{{ item.description }}</p>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </template>
    </window>

    <window windowId="operate-record" :shadow="true" class="dark" title="申诉" @windowHide="recordHide"
      v-show="record.isShow">
      <template #body>
        <div class="line pd-20">
          <div class="insert">
            <div class="attr colon">申诉描述</div>
            <div class="val">
              <el-input v-model="record.description" placeholder="请输入描述" :rows="4" type="textarea" />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="recordHide()">取消</el-button>
          <el-button type="primary" @click="appealRecord()">保存</el-button>
        </div>
      </template>
    </window>

    <el-dialog v-model="dialogVisible">
      <div class="img-view">
        <img :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
  </div>
  <ScreenPreview :prjId="project.id" :obj="screen" :isShare="true" v-if="isPreview" />
</template>

<script>
import Window from 'components/common/forms/Window';
import Page from 'components/common/forms/Page';
import Empty from 'components/common/forms/Empty';
import { prjBtnStatus, getSpaceOrPrjDetails, jumpRoute } from 'common/authBtn';
import _ from 'underscore';
import { VersionNo } from 'common/validate';
import ScreenPreview from 'views/outerChain/screen/screenPreview';

export default {
  name: 'Versions',
  components: {
    Window,
    Page,
    Empty,
    ScreenPreview
  },
  data () {
    return {
      loading: true,
      rules: {
        name: [{ required: true, message: '版本名称不能为空', trigger: 'blur' }],
        no: [{ required: true, message: '版本号不能为空(V1.0.0)', trigger: 'blur' }, { validator: VersionNo, message: '请输入正确格式版本号(V1.0.0)', trigger: 'blur' }],
      },
      project: {},
      addAuth: false,
      editAuth: false,
      delAuth: false,
      files: [],
      coverFiles: [],
      filesHistory: [],
      list: [],
      version: {
        isShow: false,
        isEdit: false,
        title: "",
        info: {
          name: '',
          description: '',
          fileIds: [],
          no: "",
        }
      },
      dialogImageUrl: '',
      dialogVisible: false,
      conf: {
        pageSize: 10,
        pageNum: 1,
        total: 10
      },
      isPreview: false,
      screen: {
        screenWidth: 1900,
        screenHeight: 1300,
        components: [],
        globals: this.$store.state.Global.globalValues
      },
      selectVersion: '',
      selectVerType: 0,
      tag: {
        isShow: false,
        title: '',
        list: [],
        keyword: "",
        value: "",
        defaultList: []
      },
      market: {
        isFree: 0,
        price: 0.00,
        isClone: 0,
        isCloneFree: 0,
        clonePrice: 0.00
      },
      selectedTagList: [],
      chooseComp: [],
      authCode: {
        title: '',
        isShow: false,
        info: {
          expireType: 'day14',
          value: 14,
          code: '',
          cloneCode: ''
        },
        share: {
          url: ''
        }
      },
      previewCode: null,
      cloneCode: null,
      shareList: [],
      detail: {
        isShow: false,
        list: []
      },
      record: {
        isShow: false,
        currentObj: {},
        description: ""
      },
      recordVersion: {
        tagIds: [],
        version: {},
        market: null
      },
    }
  },
  computed: {
    isAdmin: function () {
      return this.$store.state.user ? (this.$store.state.user.roleInfos[0].id == '-2' || this.$store.state.user.roleInfos[0].id == '0' || this.$store.state.user.roleInfos[0].id == 1) : false
    }
  },
  mounted () {
    this.getDetail();
    this.shortcutKey();
  },
  emits: ['changeNavi'],
  provide () {
    return {
      selectedComp: this.chooseComp,
      acApi: this.actionApi,
    }
  },
  methods: {
    actionApi () { },
    getDetail () {
      let self = this;
      getSpaceOrPrjDetails(self.$store).then((res) => {
        self.loading = false;
        self.project = res;
        prjBtnStatus(res.id, self.$store, (result) => {
          self.addAuth = result.addAuth;
          self.editAuth = result.editAuth;
          self.delAuth = result.delAuth;
        });
        self.getDataFn();
      })
    },
    initForm () {
      this.$refs.form.clearValidate();
    },
    getDataFn () {
      let self = this;
      self.$api.sp.getAllVersions({
        prjId: self.project.id,
        type: 0,
        pageNum: self.conf.pageNum,
        pageSize: self.conf.pageSize
      }).then((res) => {
        self.list = res.list;
        self.conf.total = res.total;
        _.each(self.list, (v) => {
          if (v.icon == '-1')
            v.icon = require("assets/images/frame/default-head.svg");
          else
            v.icon = '/file/' + v.icon;

          v.srcList = [];
          v.fileIds.forEach(f => {
            v.srcList.push('/file/' + f);
          })

          v.isMarket = false;
          v.isCase = false;
          if (v.tags.length > 0) {
            let market = _.find(v.tags, t => {
              return t.type == 2;
            })
            if (market) {
              v.isMarket = true;
              v.marketStatus = market.status;
              v.marketStatusName = self.getStatusName(market.status);
            }

            let caseObj = _.find(v.tags, t => {
              return t.type == 1;
            })
            if (caseObj) {
              v.isCase = true;
              v.caseStatus = caseObj.status;
              v.caseStatusName = self.getStatusName(caseObj.status);
            }
          }

        })
      })
    },
    versionCreate () {
      this.files = [];
      let ds = this.version;
      ds.title = "发布新版本";
      ds.isEdit = false;
      ds.info = {
        name: '',
        description: '',
        prjId: this.project.id,
        no: "",
        fileIds: []
      }
      ds.isShow = true;
      this.initForm();
    },
    versionEdit (i, list) {
      this.files = [];
      let ds = this.version
      ds.title = "编辑版本";
      ds.isEdit = true;
      ds.info = this.deepClone(list[i]);
      _.each(ds.info.fileIds, (v) => {
        this.files.push({
          id: v,
          url: '/file/' + v
        })
      })
      ds.isShow = true;
      this.initForm();
    },
    versionHide () {
      this.version.isShow = false;
    },
    versionSave () {
      let self = this;
      self.$refs.form.validate(valid => {
        if (valid) {
          self.version.info.fileIds = [];
          _.each(self.files, (v) => {
            self.version.info.fileIds.push(v.id);
          })
          if (!self.version.isEdit) {
            let self = this;
            self
              .$confirm("此操作将该项目当前数据生成版本记录, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                self.$api.sp.addVersion(self.version.info).then((res) => {
                  if (res) {
                    self.$message.success('操作成功');
                    self.getDataFn();
                    self.versionHide();
                  }
                })
              });

          } else {
            self.$api.sp.updateVersion(self.version.info).then((res) => {
              if (res) {
                self.$message.success('操作成功');
                self.getDataFn();
                self.versionHide();
              }
            })
          }
        }
      })

    },
    handleRemove (file, fileList) {
      this.files.splice(0, this.files.length, ...fileList);
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleAvatarSuccess (data) {
      let self = this;
      self.$api.file.uploadFile(data.file).then((res) => {
        self.files.push({ id: res.id, url: '/file/' + res.id });
      })
    },
    onChange (param) {
      this.conf.pageSize = param.pageSize;
      this.conf.pageNum = param.currentPage;
      this.getDataFn();
    },
    jumpSpace (n) {
      let self = this;
      if (self.project.id != n.id) {
        jumpRoute(self, n).then(() => {
          self.$router.push('/Main/SpaceDetail');
        })
      }
    },
    initData () {
      this.loading = true;
      this.conf = {
        pageSize: 10,
        pageNum: 1,
        total: 10
      };
      this.list = [];
      this.files = [];
      this.screen = {
        screenWidth: 1900,
        screenHeight: 1300,
        components: []
      }
    },
    switchVersion (i, list) {
      let self = this;
      self
        .$confirm("此操作将该项目当前数据切换为当前版本数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.$api.sp.switchVersion({
            prjId: self.project.id,
            verId: list[i].id
          }).then((data) => {
            self.$message.success('切换成功');
            self.getDetail();
          })
        });
    },
    getVerScreen (n) {
      let self = this;
      self.$api.sp.getVerScreen({
        prjId: self.project.id,
        verId: n.id
      }).then((data) => {
        self.isPreview = true;
        self.screen = data;
      })
    },
    shortcutKey () {
      let self = this;
      document.addEventListener('keyup', (event) => {
        //esc退出全屏预览
        if (event.which === 27) {
          self.isPreview = false;
          event.preventDefault();
        }
      }, false);
    },
    rePackageVersionData (n) {
      let self = this;
      self
        .$confirm("此操作将覆盖该版本数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.$api.sp.rePackageVersionData({
            prjId: self.project.id,
            verId: n.id
          }).then((data) => {
            self.$message.success('操作成功');
            self.getDetail();
          })
        });
    },
    publishVersionData (n, type) {
      let self = this;
      let tagIds = [];
      if (self.tag.list.length == 0) {
        self.$message.error('请绑定标签');
        return;
      }
      self.tag.list.forEach(v => {
        tagIds.push(v.id);
      })
      
      let flag = false, flag2 = self.adjustObjProperty(self.recordVersion.market, self.market);

      //标签未修改
      if(self.recordVersion.tagIds.length > 0) {
        tagIds.forEach(v => {
          if(!_.contains(self.recordVersion.tagIds, v)) {
            flag = true;
          }
        })
      }else {
        flag = true;
      }
     
      if(!flag || !flag2) {
        self.tagClose();
        return;
      }
      self
        .$confirm("此操作将发布该版本数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          
          let codes = [];
          if (self.cloneCode)
            codes.push(self.cloneCode);
          self.$api.sp.publishVersionData({
            type: self.selectVerType,
            verId: self.selectVersion.id,
            tagIds: tagIds,
            cover: self.coverFiles.length > 0 ? self.coverFiles[0].id : '-1',
            codes: codes,
            ...self.market
          }).then((data) => {
            self.$message.success('操作成功');
            self.getDetail();
            self.tagClose();
          })
        });
    },
    adjustObjProperty(source, target) {
      let flag = false;
      if(source) {
        for(let key in source) {
          if(source[key] != target[key]){
            flag = true;
          }
        }
      }else {
        flag = true;
      }
      return flag;
    },
    searchTags () {
      let self = this;
      self.$api.sp.selectAllTags().then((data) => {
        self.tag.defaultList = data;
        self.coverFiles = [];
        self.tag.list = _.filter(self.selectVersion.tags, v => {
          return v.type == self.selectVerType;
        });
        if (self.tag.list.length > 0) {
          let id = self.tag.list[0].cover;
          self.coverFiles = [{ id: id, url: '/file/' + id }];
          let obj = _.omit(self.tag.list[0], 'id', 'name', 'alaise', 'type', 'cover');
          self.market = { ...obj };
          self.recordVersion.market = {...obj};
          let tagIds = [];
          self.tag.list.forEach(v => {
            tagIds.push(v.id);
          })
          self.recordVersion.tagIds = tagIds;
        }

      })
    },
    removeTag (i) {
      let self = this;
      self.tag.list.splice(i, 1);
    },
    createTag () {
      let self = this;
      self.$api.sp.addTag({
        name: self.tag.value
      }).then(data => {
        self.tag.list.push({
          id: data,
          name: self.tag.value,
          alaise: self.tag.value
        })
        self.tag.defaultList.push({
          id: data,
          name: self.tag.value,
          alaise: self.tag.value
        })
        self.tag.value = '';
      })
    },
    addTag (item) {
      let self = this;
      let obj = _.find(self.tag.list, v => {
        return v.id == item.id;
      })
      if (!obj)
        self.tag.list.push(item);
    },
    tagShow (n, type) {
      switch (type) {
        case 1:
          this.tag.title = "发布案例";
          break;
        case 2:
          this.tag.title = "发布市场";
          break;
        default:
          break;
      }
      this.selectVersion = n;
      this.recordVersion.version = n;
      this.selectVerType = type;
      this.filesHistory = [];
      this.previewCode = null;
      this.cloneCode = null;
      this.market = {
        isFree: 0,
        price: 0.00,
        isClone: 0,
        isCloneFree: 0,
        clonePrice: 0.00
      }
      n.fileIds.forEach(v => {
        this.filesHistory.push({
          id: v,
          url: '/file/' + v,
        });
      })
      this.searchTags();
      this.tag.isShow = true;
    },
    tagClose () {
      this.tag.isShow = false;
    },
    handleSelectionChange (val) {
      this.selectedTagList = val;
    },
    deleteVersion (n) {
      let self = this;
      self
        .$confirm("此操作将清除该版本所有数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          self.$api.sp.deleteVersion({
            id: n.id
          }).then((data) => {
            self.$message.success('操作成功');
            self.getDetail();
          })
        });
    },
    handleConverSuccess (data) {
      let self = this;
      self.coverFiles = [];
      self.$api.file.uploadFile(data.file).then((res) => {
        self.coverFiles.push({ id: res.id, url: '/file/' + res.id });
      })
    },
    handleCoverRemove (file, fileList) {
      this.coverFiles.splice(0, this.coverFiles.length, ...fileList);
    },
    chooseCover (file) {
      this.coverFiles = [];
      this.coverFiles.push(file);
    },
    handleClose (obj, type) {
      let self = this;
      self
        .$confirm("此操作将取消发布数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let args = {
            type: type,
            verId: obj.id,
          }
          self.$api.sp.cancelPublishData(args).then(data => {
            self.$message.success("取消成功!");
            self.getDetail();
          })
        });

    },
    changeFree (val) {
      if (!val) {
        this.market.price = 0.00;
      }
    },
    changeCloneFree (val) {
      if (!val) {
        this.market.isCloneFree = 0;
        this.market.clonePrice = 0.00;
      }
    },
    changeExpire (val) {
      let result = {};
      switch (val) {
        case 'hour1':
          result = {
            expireType: 'hour1',
            value: 1,
          }
          break;
        case 'day1':
          result = {
            expireType: 'day1',
            value: 1,
          }
          break;
        case 'day3':
          result = {
            expireType: 'day3',
            value: 3,
          }
          break;
        case 'day7':
          result = {
            expireType: 'day7',
            value: 7,
          }
          break;
        case 'day14':
          result = {
            expireType: 'day14',
            value: 14,
          }
          break;
        case 'forever':
          result = {
            expireType: 'forever',
            value: -1,
          }
          break;
        default:
          result = {
            expireType: 'day14',
            value: 14,
          }
          break;
      }
      this.authCode.info = { ...this.authCode.info, ...result };
    },
    shareShow (item) {
      this.selectVersion = item;
      this.authCode.title = '分享'

      this.authCode.isShow = true;
      this.authCode.info = {
        expireType: 'day14',
        value: 14,
        code: '',
        diableClone: false,
        cloneCode: '',
        prjId: this.project.id,
        module: 0,
        type: 0
      }
    },
    shareHide () {
      this.authCode.isShow = false;
    },
    saveShareCode () {
      let self = this;
      self.previewCode = {
        ...self.authCode.info,
        ... {
          key: self.selectVersion.id,
        }
      }
      self.$api.sp.saveCode(self.previewCode).then(data => {
        if (self.authCode.info.code)
          self.authCode.share.url = window.origin + '/ScreenPreview?shareId=' + data + '&code=' + self.authCode.info.code;
        else
          self.authCode.share.url = window.origin + '/ScreenPreview?shareId=' + data;

        self.authCode.share.show = true;
        self.authCode.info.expireName = self.getExpireName(self.authCode.info.expireType);
        self.$message.success('操作成功');
      })
      self.shareHide();
    },
    getExpireName (type) {
      let result = '';
      switch (type) {
        case 'hour1':
          result = '1小时';
          break;
        case 'day1':
          result = '1天';
          break;
        case 'day3':
          result = '3天';
          break;
        case 'day7':
          result = '7天';
          break;
        case 'day14':
          result = '14天';
          break;
        case 'forever':
          result = '永久';
          break;
      }
      return result;
    },
    shareUrlHide () {
      this.authCode.share.show = false;
    },
    copy () {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = this.authCode.share.url;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");

      this.$message({
        type: "success",
        message: "分享链接复制成功"
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    applyCase (obj) {
      let self = this;
      self
        .$confirm("是否申请案例?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let args = {
            verId: obj.id
          }
          self.$api.sp.applyCaseData(args).then(data => {
            self.$message.success("申请成功，请等待管理员审批!");
            self.getDetail();
          })
        });

    },
    getStatusName (status) {
      let result = "";
      switch (status) {
        case 0:
          result = "审核中";
          break;
        case 1:
          result = "驳回";
          break;
        case 2:
          result = "审核通过";
          break;
        case 3:
          result = "申诉中";
          break;
        default:
          result = "全部";
          break;
      }
      return result;
    },
    getStatusColor (status) {
      let result = "";
      switch (status) {
        case 0:
          result = "rgba(30, 170, 255, 1)";
          break;
        case 1:
          result = "rgba(235, 43, 12, 1)";
          break;
        case 2:
          result = "rgba(55, 250, 200, 1)";
          break;
        case 3:
          result = "rgba(250, 115, 0, 1)";
          break;
        default:
          result = "";
          break;
      }
      return result;
    },
    detailShow (obj, type) {
      let self = this;
      let tag = _.find(obj.tags, v => { return v.type == type });
      if (tag.status == 3) {
        self.$api.sp.selectAppealRecords({
          pubId: tag.pubId,
          status: 3
        }).then(data => {
          self.detail.list = data;
          self.detail.list.forEach(e => {
            e.head = require('assets/images/frame/default-head.svg');
            e.statusName = self.getStatusName(e.status);
            e.color = self.getStatusColor(e.status);
          })
          self.detail.isShow = true;
        })
      }
    },
    detailHide () {
      this.detail.isShow = false;
    },
    showAppealRecord (obj, type) {
      if (type == 1 && obj.marketStatus != 2) {
        this.$message.error("市场未审批通过，无法申请案例");
        return;
      }
      this.record.currentObj = { ...obj };
      this.record.currentType = type;
      this.record.description = "";
      this.record.isShow = true;
    },
    appealRecord () {
      let self = this;
      let tag = _.find(self.record.currentObj.tags, v => { return v.type == self.record.currentType });
      self.$api.sp
        .appealRecord({
          pubId: tag.pubId,
          description: self.record.description
        })
        .then(data => {
          if (data) {
            self.$message.error("申诉已回复!");
            self.recordHide();
          }
        });
    },
    recordHide () {
      this.record.isShow = false;
    },
    getCheckStatusClass (obj, type) {
      let result = 'examine ', status = 0;
      if(type == 1) {
        status = obj.caseStatus;
      }else if(type == 2) {
        status = obj.marketStatus;
      } 
      switch(status) {
        case 0:
          result += "examining";
          break;
        case 1:
          result += "reject";
          break;
        case 2:
          result += "pass";
          break;
        case 3:
          result += "appeal";
          break;
        default:
          break; 
      }
      return result;
    },
    getCheckStatusName(obj ,type) {
      let result = '', status = 0;
      if(type == 1) {
        status = obj.caseStatus;
      }else if(type == 2) {
        status = obj.marketStatus;
      } 
      switch(status) {
        case 0:
          result = "审核中";
          break;
        case 1:
          result += "驳回";
          break;
        case 2:
          result += "审核通过";
          break;
        case 3:
          result += "申诉中";
          break;
        default:
          break; 
      }
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin clockwise-animate($time: 2s) {
  -webkit-animation: clockwise-animate $time infinite linear;
  -moz-animation: clockwise-animate $time infinite linear;
  -o-animation: clockwise-animate $time infinite linear;
  animation: clockwise-animate $time infinite linear;
}

.box-card {
  margin-bottom: 24px;

  .card-header {
    align-items: center;

    span {
      font-size: 18px;
      color: #fff;
    }

    .right {
      width: 1%;
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  pre {
    width: 100%;
    float: left;
    line-height: 1.4;
    color: #fff;
    font-size: 14px;
    font-family: inherit;
    margin-bottom: 16px;
  }

  .pic-area {
    width: 100%;
    float: left;

    img {
      height: 16vh;
      float: left;
      margin: 0 16px 16px 0;
    }
  }

  .card-info {
    width: 100%;
    float: left;
    background: rgba(255, 255, 255, 0.04);
    margin-bottom: 20px;
    padding: 10px;
    line-height: 20px;
    border-radius: 4px;
    color: #fff;

    i {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      font-style: normal;
      margin: 0 4px 0 30px;
      opacity: 0.4;

      &:first-child {
        margin-left: 0;
      }
    }

    .card-logo {
      width: 20px;
      height: 20px;
      margin: 0 20px;
    }
  }
}

.img-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    max-width: 100%;
  }
}

.tag-default-group {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 4px;
  border-radius: 4px;
  max-height: 80px;

  .tag-default {
    padding: 4px 8px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    float: left;
    margin: 4px;
    cursor: pointer;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
    }
  }
}

.tag-area {
  width: 100%;
  float: left;

  .tag {
    height: 28px;
    float: left;
    display: flex;
    background: rgb(87, 167, 228);
    border-radius: 0 4px 4px 0;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    position: relative;
    margin: 0 8px 8px 14px;

    &:before {
      content: "";
      width: 14px;
      height: 28px;
      border-right: 14px solid rgb(87, 167, 228);
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      display: block;
      position: absolute;
      top: 0;
      left: -14px;
    }

    span {
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;
    }

    .tag-close {
      width: 28px;
      height: 28px;
      position: relative;
      margin: 0 -8px 0 8px;
      cursor: pointer;

      &:hover {

        &:before,
        &:after {
          background: #fff;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 14px;
        height: 2px;
        background: rgb(179, 207, 218);
        margin: -1px -7px;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.opt-layet {
  width: 100%;
  padding: 0 0 0 50px;
  display: inline-block;

  img {
    width: 40px;
    height: 40px;
    background: #ddd;
    border-radius: 50%;
    float: left;
    margin: 0 -50px;
  }

  h4,
  p {
    float: left;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    line-height: 1;

    &:hover {
      color: rgba(115, 200, 255, 1);
    }
  }

  p {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
    line-height: 1.4;

    span {
      cursor: pointer;
      color: rgba(30, 170, 255, 1);

      &:hover {
        color: rgba(115, 200, 255, 1);
      }
    }
  }
}

:deep() {
  .my-tag {
    margin-left: 8px;

    span {
      font-size: 14px;
      display: flex;

      b {
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
      }
    }

    .examine {
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      display: inline-block;
      margin-left: 8px;
      overflow: hidden;

      &:before,
      &:after {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -10px;
        pointer-events: none;
      }

      &:before {
        content: "\ef72";
        font-family: "beicon";
        z-index: 1;
      }

      &:after {
        font-family: 'icomoon';
        z-index: 10;
        font-size: 12px;
        margin-top: -7px;
        color: rgba(255, 255, 255, .8);
      }

      &.examining {
        &:before {
          color: rgb(0, 130, 180);
        }

        &:after {
          content: "\e97a";
          @include clockwise-animate(4s);
        }
      }

      &.pass {

        &:before {
          color: rgb(0, 160, 75);
        }

        &:after {
          content: "\ea10";
          transform: scale(.7);
        }
      }

      &.reject {

        &:before {
          color: rgb(160, 30, 0);
        }

        &:after {
          content: "\ea0f";
          transform: scale(.6);
        }

      }

      &.appeal {

        &:before {
          color: rgb(0, 145, 135);
        }

        &:after {
          content: "\ea32";
          transform: scale(.7);
        }

      }
    }
  }
}

/*顺时针转动*/
@-webkit-keyframes clockwise-animate {
  0% {
    -webkit-transform: rotate(0deg) scale(.8);
  }

  100% {
    -webkit-transform: rotate(360deg) scale(.8);
  }
}

@-moz-keyframes clockwise-animate {
  0% {
    -moz-transform: rotate(0deg) scale(.8);
  }

  100% {
    -moz-transform: rotate(360deg) scale(.8);
  }
}

@-o-keyframes clockwise-animate {
  0% {
    -o-transform: rotate(0deg) scale(.8);
  }

  100% {
    -o-transform: rotate(360deg) scale(.8);
  }
}

@keyframes clockwise-animate {
  0% {
    transform: rotate(0deg) scale(.8);
  }

  100% {
    transform: rotate(360deg) scale(.8);
  }
}
</style>