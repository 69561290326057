import axios from 'axios'
import { ElMessage } from 'element-plus'
import qs from 'qs'
import _ from 'underscore'
import { ACCESS_TOKEN_KEY, COMMON_DES_KEY } from './../common/keys'

const CancelToken = axios.CancelToken;
const pendingRequest = new Map();

// 避免全局修改导致其他框架问题
const modifyPromise = (promise) => {
  promise.oldThe = promise.then;
  promise.then = function(onFulfilled, onRejected) {
    return promise.oldThe(onFulfilled, onRejected || onError);
  }
  return promise;
}

// 获取请求key
const getReqKey = (config) => {
  // 请求方式、请求地址、请求参数生成的字符串来作为是否重复请求的依据
  const { method, url, params, data } = config; // 解构出来这些参数
  // GET ---> params  POST ---> data
  const requestKey =  [ method, url, qs.stringify(params), qs.stringify(data)].join('&');
  return requestKey;
}
 
// 取消重复请求
const removeReqKey = (key) => {
  const cancelToken = pendingRequest.get(key);
  cancelToken(key); // 取消之前发送的请求
  pendingRequest.delete(key); // 请求对象中删除requestKey
}

const removeAllReqKey = () => {
  pendingRequest.forEach((value, key) => {
    value(key);
    pendingRequest.delete(key);
  })
}

const onError = (res) => {
  ElMessage.error(res.msg || defaultError().msg);
}

const service = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 60 * 1000
});

const getToken = () => {
  const accessToken = Tools.getLocal(COMMON_DES_KEY, ACCESS_TOKEN_KEY);
  if (!accessToken) {
    return '';
  }
  return `Bearer ${accessToken}`;
}

const defaultError = (state, msg) => {
  return {
    state: `${state || 'fail'}`,
    msg: msg || '网络出了小差，请稍后再试。',
  }
}

const getReqConfig = (opt, type) => {
  let _config = { headers: {} };
  if (opt) { 
    if (!opt.ignoreToken && opt.headers && opt.headers.Authorization) {
      _config.headers.Authorization = opt.headers.Authorization
    }
  }else {
    const token = getToken();
    if (token) {
      _config.headers.Authorization = token;
    }
  }
  if ('form' == type) {
    _config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
  } else if ('file' == type) {
    _config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8'
  }
  return _config;
}

service.interceptors.request.use(config => {
  
  // 获取请求key
  let requestKey = getReqKey(config);
 
  // 判断是否是重复请求
  if (pendingRequest.has(requestKey)) { // 是重复请求
    removeReqKey(requestKey); // 取消
  }else{
    // 设置cancelToken
    config.cancelToken = new CancelToken(function executor(cancel) {
      pendingRequest.set(requestKey, cancel); // 设置
    })
  }
  return config;
}, err => {
  console.error(err);
  return Promise.reject(defaultError());
});
service.interceptors.response.use(res => {
  if (res.status >= 200 &&
    res.status < 400) {
    if (res.data && res.data.state) {
      let requestKey = getReqKey(res.config);
      switch (res.data.state) {
        case 'success':
          return res.data.obj;
        case '1':
        case '3':
          //取消后续请求
          removeAllReqKey();
          Tools.removeLocal();
          ElMessage.error({
            duration: 1000,
            message: '请重新登录！',
            onClose: () => {
              window.location.href = '/Index';
            }
          });
          return new Promise(() => {});
        case '2':
          ElMessage.error(res.data.msg || '无权限');
          return;
        case '4':
          //取消后续请求
          removeAllReqKey();
          Tools.removeLocal();
          ElMessage.error({
            duration: 1000,
            message: res.data.msg || '您的帐号在其它地方登录，您已被迫下线，如果不是您本人操作，请及时修改密码!',
            onClose: () => {
              window.location.href = '/Index';
            }
          });
          
          return;
        default:
          return Promise.reject(defaultError(res.data.state,res.data.msg));
      }
    } else {
      return res.data;
    }
  }
  return Promise.reject(defaultError(res.status));
}, err => {
  // if(err && err.response) {
  //   let code = err.response.status;    
  // }
  if (axios.isCancel(err)) { // 取消请求的情况下，终端Promise调用链
    return new Promise(() => {});
  } else {
    return Promise.reject(defaultError(err.response ? err.response.state: err.message));
  }
  
});


const request = {
  get: (url, params, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    let config = getReqConfig(opt);
    if (params) {
      config.params = params,
        config.paramsSerializer = params => {
          return qs.stringify(params, { indices: false })
        }
    }
  
    return  modifyPromise(new Promise((resolve, reject) => {
      service.get(url, config)
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  getIgToken: (url, params) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    let config = {};
    if (params) {
      config.params = params,
        config.paramsSerializer = params => {
          return qs.stringify(params, { indices: false })
        }
    }
  
    return  modifyPromise(new Promise((resolve, reject) => {
      service.get(url, config)
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  post : (url, data, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    return modifyPromise(new Promise((resolve, reject) => {
      service.post(url, data, getReqConfig(opt))
        .then(res => resolve(res))
        .catch(err => {
          console.info(err);
          reject(err)
        })
    }));
  },
  postIgToken : (url, data) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    return modifyPromise(new Promise((resolve, reject) => {
      service.post(url, data)
        .then(res => resolve(res))
        .catch(err => {
          console.info(err);
          reject(err)
        })
    }));
  },
  // 适用于Content-Type'为'application/x-www-form-urlencoded post请求
  postForm : (url, data, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    return modifyPromise(new Promise((resolve, reject) => {
      service.post(url, qs.stringify(data), getReqConfig(opt, 'form'))
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  upload : (url, data, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    return modifyPromise(new Promise((resolve, reject) => {
      service.post(url, data, getReqConfig(opt, 'file'))
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  getJSON : (url)=>{
    return service.get(url);
  }
}

export default request;
