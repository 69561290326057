<!--api-->
<template>
  <div class="dark">
    <div class="line" style="margin-bottom: 8px">
      <div class="left">
        <el-input v-model="request.url" placeholder="请输入地址..." class="input-with-select left" style="width: 40vw"
          :disabled="disabled">
          <template #prepend>
            <el-select v-model="request.select" placeholder="Select" style="width: 100px" :disabled="disabled">
              <el-option label="GET" value="GET" />
              <el-option label="POST" value="POST" />
            </el-select>
          </template>
        </el-input>
      </div>
      <el-button type="primary" class="left" style="margin: 0 4px" @click="sendRequest()"
        :disabled="sendStatus">{{ sendStatus
          ? '发送中...' : '发送'}}</el-button>
      <el-button plain size="small" class="left" style="padding: 5px 14px">代理
        <el-switch v-model="request.proxy" inline-prompt active-text="开" inactive-text="关" style="margin: 4px 0 4px 4px"
          :disabled="disabled" /></el-button>
    </div>
    <div class="line">
      <el-tabs v-model="activeName" type="card" class="demo-tabs line">
        <el-tab-pane label="请求头" name="first">
          <el-table :data="request.headers" style="width: 100%" v-if="activeName == 'first'">
            <el-table-column label="键名" prop="key">
              <template #default="scope">
                <el-input v-model="request.headers[scope.$index].key" placeholder="请输入键名.." :disabled="disabled" />
              </template>
            </el-table-column>
            <el-table-column label="值" prop="value">
              <template #default="scope">
                <el-input v-model="request.headers[scope.$index].value" placeholder="请输入值.." :disabled="disabled" />
              </template>
            </el-table-column>
            <el-table-column label="默认值" prop="default">
              <template #default="scope">
                <el-input v-model="request.headers[scope.$index].default" placeholder="请输入默认值.." :disabled="disabled" />
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="10%" v-if="!disabled">
              <template #default="scope">
                <el-button link size="small" @click="deleteLine(1, request.headers[scope.$index])">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="create-line" @click="createLine(1)" v-if="!disabled">新增数据</div>
        </el-tab-pane>
        <el-tab-pane label="请求参数" name="second">
          <div v-if="request.select == 'POST'">
            <el-radio-group v-model="request.paramType" style="margin: 0 20px 4px 0" :disabled="disabled">
              <el-radio :label="0">form-data</el-radio>
              <el-radio :label="1">x-www-form-urlencoded</el-radio>
              <el-radio :label="2">raw</el-radio>
            </el-radio-group>
            <el-select v-model="request.paramFormat" placeholder="请选择" size="small" v-if="request.paramType == 2"
              :disabled="disabled">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <div class="right" v-if="!disabled">
              <el-button v-if="(request.paramType == 2)" :type="defaultBtn" size="small"
                @click="showDefault()">默认值</el-button>
            </div>
          </div>
          <div v-if="request.paramType == 2">
            <div class="edit-title">编辑数据</div>
            <div class="edit-container" style="height: 200px">
              <v-ace-editor v-model:value="request.paramContent" lang="json" theme="tomorrow_night_blue"
                :options="aceConfig.options" style="height: 100%" :readonly="disabled" />
            </div>
          </div>
          <div v-if="request.paramType == 0 ||
            request.paramType == 1 ||
            request.select == 'GET'
          ">
            <el-table :data="request.params" style="width: 100%">
              <el-table-column label="键名" prop="key">
                <template #default="scope">
                  <el-input v-model="request.params[scope.$index].key" placeholder="请输入键名.." :disabled="disabled" />
                </template>
              </el-table-column>
              <el-table-column label="值" prop="value">
                <template #default="scope">
                  <el-input v-model="request.params[scope.$index].value" placeholder="请输入值.." :disabled="disabled" />
                </template>
              </el-table-column>
              <el-table-column label="默认值" prop="default">
                <template #default="scope">
                  <el-input v-model="request.params[scope.$index].default" placeholder="请输入默认值.."
                    :disabled="disabled" />
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="10%" v-if="!disabled">
                <template #default="scope">
                  <el-button link size="small" @click="deleteLine(2, request.params[scope.$index])">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="create-line" @click="createLine(2)" v-if="!disabled">新增数据</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="请求前处理 " name="third">
          <div class="edit-title">
            请求前处理
            <!-- <el-button size="small" class="right" type="info"
                >数据处理</el-button> -->
          </div>
          <div class="edit-container" style="height: 200px">
            <v-ace-editor v-model:value="request.preDealFn" lang="json" theme="tomorrow_night_blue"
              :options="aceConfig.options" style="height: 100%" :readonly="disabled" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="请求后处理" name="fourth">
          <div class="edit-title">
            请求后处理
            <!-- <el-button size="small" class="right" type="info"
                >数据处理</el-button> -->
          </div>
          <div class="edit-container" style="height: 200px">
            <v-ace-editor v-model:value="request.dealFn" lang="json" theme="tomorrow_night_blue"
              :options="aceConfig.options" style="height: 100%" :readonly="disabled" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="line">
      <div class="edit-title" style="margin-top: 10px;">响应数据</div>
      <div class="edit-container" style="height: 200px">
        <v-ace-editor v-model:value="request.result" @init="editorInit" lang="json" theme="tomorrow_night_blue"
          :options="aceConfig.options" :readonly="true" style="height: 100%" />
      </div>
      <div class="edit-container-cover" style="height: 240px;margin:-240px 0 0" v-if="sendStatus">
        <span>发送请求中...</span>
        <el-button @click="cancelRequest">取消发送</el-button>
      </div>
    </div>
    <window class="dark large" title="默认值" windowId="edit-default" v-if="openDefault" @windowHide="hideDefault">
      <template #body>
        <v-ace-editor class="line" v-model:value="request.paramDefault" lang="json" theme="tomorrow_night_blue"
          :options="aceConfig.options" style="height: 40vh" />
      </template>
      <template #footer>
        <div class="line pd-20 align-right" id="aaa">
          <el-button @click="hideDefault()">取消</el-button>
          <el-button type="primary" @click="saveDefault()">保存</el-button>
        </div>
      </template>
    </window>
  </div>
</template>
<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/ext-language_tools";
import "brace/theme/tomorrow_night_blue";
import "brace/mode/json";
import "brace/mode/javascript";
import Window from "components/common/forms/Window";
import { ref, nextTick, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";
import { ACCESS_TOKEN_KEY, COMMON_DES_KEY } from 'common/keys'

export default {
  name: "api",
  components: {
    VAceEditor,
    Window
  },
  props: {
    config: {
      type: Object,
      default: {}
    },
    currentPage: {
      type: Array,
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { expose, emit }) {
    const store = useStore();
    let dealResult = ref(""),
      activeName = ref("first"),
      aceConfig = ref({
        lang: "json", //解析json
        readOnly: false, //是否只读
        options: {
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          tabSize: 2,
          showPrintMargin: false,
          fontSize: 14
        }
      }),
      request = ref({
        url: "",
        select: "GET",
        proxy: false,
        headers: [
          {
            key: "key",
            value: "value",
            default: ""
          }
        ],
        params: [
          {
            key: "key",
            value: "value",
            default: ""
          }
        ],
        result: "",
        paramType: 0,
        paramFormat: 1,
        dealFn: "(data) => {return data;}",
        preDealFn:
          "(headers, params) => {return {headers: headers, params: params}}",
        paramContent: "",
        paramDefault: ""
      }),
      options = ref([
        {
          value: 1,
          label: "Text"
        },
        {
          value: 2,
          label: "JSON"
        }
      ]), openDefault = ref(false), defaultBtn = ref("primary"), sendStatus = ref(false);

    const global = store.state.Global.globalValues;
    const page = store.state.Global.pageValues;
    const axios = getCurrentInstance().appContext.config.globalProperties.axios;
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
    const CancelToken = axios.CancelToken;

    let cancelFn;

    const init = () => {
      let header = "",
        params = "";

      try {
        if (props.config.requestHeaders)
          header = JSON.parse(props.config.requestHeaders);
        if (props.config.requestParams)
          params = JSON.parse(props.config.requestParams);
      } catch (error) {
        header = eval(`
        let header=${props.config.requestHeaders};
        header;
        `);

        params = eval(`
        let params=${props.config.requestParams};
        params;
        `);
      }

      if (props.config.header) header = props.config.header;
      if (props.config.params) params = props.config.params;

      if (
        props.config.requestUrl &&
        props.config.requestUrl.indexOf("?") &&
        props.config.requestMethod == "GET"
      ) {
        let index = props.config.requestUrl.indexOf("?");
        if (index != -1) {
          let paramUrl = props.config.requestUrl.substring(
            index + 1,
            props.config.requestUrl.length
          );
          let args = paramUrl.split("&");
          let obj = {};
          if (args.length > 0) {
            args.forEach(v => {
              let param = v.split("=");
              obj[param[0]] = param[1];
            });

            if (_.isArray(params)) {
              for (let key in obj) {
                let p = _.find(params, v => {
                  return v.key == key;
                });
                if (p) {
                  params.forEach(v => {
                    if (v.key == key) {
                      v.value = obj[key];
                    }
                  });
                } else {
                  params.push({
                    key: key,
                    value: obj[key],
                    default: ""
                  });
                }
              }
            }
          }
        }
      }

      request.value = {
        headers: !header
          ? [
            {
              key: "",
              value: "",
              default: ""
            }
          ]
          : header,
        params: !params
          ? [
            {
              key: "",
              value: "",
              default: ""
            }
          ]
          : params,
        proxy: props.config.disableProxy,
        url: props.config.requestUrl,
        select: props.config.requestMethod,
        paramType: !props.config.paramType ? 0 : props.config.paramType,
        paramFormat: !props.config.paramFormat ? 1 : props.config.paramFormat,
        dealFn: !props.config.dealFn
          ? "(data) => {return data;}"
          : props.config.dealFn,
        preDealFn: !props.config.preDealFn
          ? "(headers, params) => {return {headers: headers, params: params}}"
          : props.config.preDealFn,
        paramContent: !props.config.paramContent
          ? ""
          : props.config.paramContent,
        paramDefault: !props.config.paramDefault
          ? ""
          : props.config.paramDefault,
        result: ""
      };

      if (request.value.paramDefault)
        defaultBtn.value = 'success';
      else
        defaultBtn.value = 'primary';
    };

    const createLine = type => {
      if (type == 1) {
        request.value.headers.push({
          key: "",
          value: "",
          default: ""
        });
      } else if (type == 2) {
        request.value.params.push({
          key: "",
          value: "",
          default: ""
        });
      }

      activeName.value = activeName.value + "_";
      nextTick(() => {
        activeName.value = activeName.value.substring(
          0,
          activeName.value.length - 1
        );
      });
    };

    const deleteLine = (type, item) => {
      let index = -1;
      if (type == 1) {
        index = _.findIndex(request.value.headers, v => {
          return v.key == item.key;
        });
        if (index != -1) request.value.headers.splice(index, 1);
      } else if (type == 2) {
        index = _.findIndex(request.value.params, v => {
          return v.key == item.key;
        });
        if (index != -1) request.value.params.splice(index, 1);
      }

      activeName.value = activeName.value + "_";
      nextTick(() => {
        activeName.value = activeName.value.substring(
          0,
          activeName.value.length - 1
        );
      });
    };

    const returnConfig = () => {
      let result = {
        requestUrl: request.value.url,
        requestMethod: request.value.select,
        requestParams: JSON.stringify(request.value.params),
        requestHeaders: JSON.stringify(request.value.headers),
        paramType: request.value.paramType,
        paramFormat: request.value.paramFormat,
        disableProxy: request.value.proxy,
        dealFn: request.value.dealFn,
        preDealFn: request.value.preDealFn,
        paramContent: request.value.paramContent,
        paramDefault: request.value.paramDefault,
        sourceData: request.value.sourceData,
        result: request.value.result
      };
      return result;
    };

    const editorInit = () => { };

    const cancelRequest = () => {
      sendStatus.value = false;
      if (cancelFn) {
        cancelFn();
        request.value.result = request.value.sourceData = '';
      }
    };

    const sendRequest = () => {
      cancelFn = null;
      sendStatus.value = true;
      let header = constructData(request.value.headers),
        params = constructData(request.value.params);

      let newUrl = adaptParams(request.value.url);
      let pFn = adaptParams(request.value.preDealFn);
      let dFn = adaptParams(request.value.dealFn);
      let preFn = eval(pFn),
        dealFn = eval(dFn);
      let result = preFn(header, params);
      (header = result.headers), (params = result.params);

      if (request.value.select == 'POST') {
        let pContent = adaptParams(request.value.paramContent),
          pDefault = adaptParams(request.value.paramDefault);

        if (pContent) {
          let cont, def;
          try {
            if (pContent)
              cont = JSON.parse(pContent);
            if (pDefault)
              def = JSON.parse(pDefault);
          } catch (error) {
            if (pContent) {
              cont = eval(`
              let cont=${pContent};
              cont;
              `);
            }


            if (pDefault) {
              def = eval(`
              let def=${pDefault};
              def;
              `);
            }

          }

          let pcObj = dealParamContent(cont, def);
          params = pcObj;
        }
      }


      if (!request.value.proxy) {
        if (request.value.select == "GET") {
          let url = newUrl;
          for (let key in params) {
            if (url.indexOf("?") != -1) {
              url = url + "&" + key + "=" + params[key];
            } else {
              url = url + "?" + key + "=" + params[key];
            }
          }
          axios.get(url, {
            headers: {...header},
            
          }).then(data => {
            request.value.sourceData = JSON.stringify(data.data, null, "\t");
            let res = dealFn(data.data);
            request.value.result = JSON.stringify(res, null, "\t");
            sendStatus.value = false;
          });
        } else if (request.value.select == "POST") {
          axios.post(newUrl, params, {
            ...header, cancelToken: new CancelToken((c) => {
              cancelFn = c;
            })
          }).then(data => {
            request.value.sourceData = JSON.stringify(data.data, null, "\t");
            let res = dealFn(data.data);
            request.value.result = JSON.stringify(res, null, "\t");
            sendStatus.value = false;
          });
        }
      } else {
        axios.post('/sp/project/httpClientInterface', {
          type: request.value.select,
          url: newUrl,
          params: JSON.stringify(params),
          header: JSON.stringify(header)
        }, {
          headers: {
            Authorization: getToken(),
          },
          cancelToken: new CancelToken((c) => {
            cancelFn = c;
          })
        }).then(data => {
          request.value.sourceData = JSON.stringify(data.data.obj, null, "\t");
          let res = JSON.parse(data.data.obj);
          res = dealFn(res);
          request.value.result = JSON.stringify(res, null, "\t");
          request.value.result = dealFn(request.value.result);
          sendStatus.value = false;
        });
      }
    };

    const getToken = () => {
      const accessToken = Tools.getLocal(COMMON_DES_KEY, ACCESS_TOKEN_KEY);
      if (!accessToken) {
        return '';
      }
      return `Bearer ${accessToken}`;
    }

    const constructData = data => {
      let result = {};
      data.forEach(v => {
        let pKey = adaptParams(v.key);
        let pValue = adaptParams(v.value);
        let pDefault = adaptParams(v.default);
        if (pKey) result[pKey] = !pValue ? pDefault : pValue;
      });

      return result;
    };

    const showDefault = () => {
      openDefault.value = true;
    };

    const hideDefault = () => {
      openDefault.value = false;
    };

    const saveDefault = () => {
      if (request.value.paramDefault)
        defaultBtn.value = 'success';
      else
        defaultBtn.value = 'primary';

      hideDefault();
    };

    const adaptParams = (data) => { 
      //匹配@() 参数
      let matches = data.match(/\@\((.+?)\)/gi);
      //匹配到
      if (matches) {
        let obj = {};
        matches.forEach(p => {
          //获取key
          let key = p.replace('@(', '');
          key = key.replace(')', '');
          let args = key.split(".");

          //全局参数
          let gValue = _.find(global, cp => {
            return cp.key == key;
          });
          if (gValue) {
            if (gValue.value == "true" || gValue.value == "false")
              gValue.value = !!gValue.value;
            obj[p] = gValue.value;
          }

          //页面参数
          if (args.length > 1) {
            for (let sheet in page) {
              if (args[0] == sheet) {
                let pValue = _.find(page[sheet], (cp) => {
                  return cp.key == args[1];
                })
                if (pValue) {
                  if (pValue.value == 'true' || pValue.value == 'false')
                    pValue.value = !!pValue.value;
                  obj[p] = pValue.value;
                }
              }
            }
          }
        })

        //替换数据
        for (let key in obj) {
          if (data.indexOf(`"${key}"`) != -1 && typeof obj[key] == "boolean")
            data = data.replaceAll(`"${key}"`, obj[key]);

          data = data.replaceAll(key, obj[key]);
        }
      }
      return data;
    };

    const dealParamContent = (data, def) => {
      let result = {};
      if (def) {
        for (let key in def) {
          result[key] = def[key];
        }

        for (let key2 in data) {
          if (!data[key2] && def[key2])
            result[key2] = def[key2];
          else
            result[key2] = data[key2];
        }
      } else {
        result = data;
      }

      return result;
    };

    expose({
      init,
      returnConfig
    });

    onMounted(() => {
      init();
    });

    return {
      dealResult,
      activeName,
      aceConfig,
      request,
      options,
      openDefault,
      defaultBtn,
      sendStatus,

      createLine,
      editorInit,
      deleteLine,
      sendRequest,
      showDefault,
      hideDefault,
      saveDefault,
      cancelRequest
    };
  }
};
</script>
