import request  from '../request'
const commonUrl = "/sp";
const sp = {
    /**
     * 分享
     */
    
    getAllModels(data) {
        return request.get(commonUrl + '/share/getAllModels', data, {ignoreToken: true});
    },
    getScreenOutData(data) {
        return request.get(commonUrl + '/share/getScreenOutData', data, {ignoreToken: true});
    }, 
}

export default sp;